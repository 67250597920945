.publishers-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 30px;
}
.publishers-main-hidden{
    display: none;
}
.publishers-page-title{
    color: rgb(14, 93, 220);
    padding: 2px;
    font-size: 16px;
}

.publisher-detail-hidden{
    display: none;
}
.publisher-detail{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px;
background-color: #f5f2f2;
}
.payment-modal{
    width: 90%;
    margin: auto;
    padding: 30px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px;
flex-direction: column;
gap: 20px;
background: radial-gradient(circle at center, rgb(110, 110, 207) 0, rgb(28, 28, 118), rgb(237, 241, 237) 100%);
}
.payment-modal-hidden{
display: none;
}
.publishers-main-dashboard-detail{
    width: 100%;
}
.approved,.pending,
.rejected{
font-size: 12px;
font-weight: bold;
text-shadow: 1px 1px 1px 1px gray;
}
.approved{
    color: white;
}
.pending{
    color: darkgoldenrod;
}
.rejected{
    color: red;
}
.icon{
    cursor: pointer;
    color: rgb(67, 61, 61);
}
.approved-btn,.pending-btn,
.rejected-btn{
font-size: 12px;
font-weight: lighter;
padding: 5px 10px;
background: transparent;
border: thin solid gray;
border-radius: 3px;
}
.approved-btn{
    color: rgb(14, 93, 220);
}
.pending-btn{
    color: darkgoldenrod;
}
.rejected-btn{
    color: red;
}

.main-items-container{
    display: flex;
justify-content: space-between;
align-items: baseline;
flex-wrap: wrap;
gap: 10px;
width: 95%;
margin: auto;
}
.publishers-main-dashboard{
box-shadow: 2px 1x 1px 1px rgb(220, 220, 220);
flex-basis: 35%;
width: 100%;
max-height: fit-content;
border: thin solid gray;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
padding: 20px;
}
.author-container-dashboard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
    background-color: var(--dashboard-bg);
}
.author-title-dashboard{
color: white;
font-size: 12px;
font-weight: bold;
}
.author-city-dashboard{
color: #eee;
font-size: 16px;
font-weight: lighter;
}
.book-container-dashboard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
}
.book-info{
    font-weight: lighter;
    font-size: 14px;
    padding: 0 20px;
}

.publisher-detail-error{
    color: red;
    font-size: 12px;
    padding: 10px;
}
.publisher-detail-success{
    color: var(--primary-color);
    font-size: 12px;
    padding: 10px;
}
.action-btn{
    padding: 4px 8px 4px 8px !important;
    border: thin solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    font-weight: lighter !important;
    background-color: white !important;
    text-decoration: none !important;
}
.add-book-form{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 15px;
}
.add-book-form-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.add-book-input{
    flex-basis: 25%;
    width: 100%;
    padding: 10px;
    font-weight: lighter;
    font-size: 12px;
    border: thin solid gray;
    border-radius: 4px;
}
.add-book-text-area{
    flex-basis: 25%;
    width: 100%;
    padding: 10px;
    font-weight: lighter;
    font-size: 12px;
    border: thin solid gray;
    border-radius: 4px;
    height: 150px;
}
.add-book-btn{
    padding: 4px 8px 4px 8px;
    border: thin solid var(--primary-color);
    color: var(--primary-color);
    background-color: white;
    font-size: 14px;
    font-weight: lighter;
    border-radius: 4px;
}
.add-new-book-item-wrapper{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: center;
    gap: 10px;
    margin-left: 20px;
    padding: 10px 20px;
    border-radius: 4px;
    border: thin solid rgb(216, 209, 209);
}
.add-new-book-item-text{
    font-weight: 300;
    font-size: 12px;
    color: var(--primary-color);
}
.paypal-img{
    width: 150px;
}
.payment-inner-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

/* Mobil View */
@media only screen and (max-width:400px) {
    .publishers-main-dashboard{
        flex-basis: 100%;
        width: 100%;
        padding: 0;
    }
    
    .book-container-dashboard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 10px;
    }
    
    .payment-modal{
        /* background-color: red; */
    }
    .publisher-detail{
        margin: 20px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }
    .publishers-main{
        gap: 20px;
        justify-content: space-between;
        padding: 20px;
    }
    .add-book-form-container{
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    }
    .add-book-input{
        flex-basis: 100%;
        font-weight: lighter;
        font-size: 12px;
        border: thin solid gray;
        border-radius: 4px;
    }
    .add-book-text-area{
        flex-basis: 100%;
        width: 100%;
        font-weight: lighter;
        font-size: 12px;
        border: thin solid gray;
        border-radius: 4px;
    }
}