*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.submission-options-main{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.submission-options-header{
width: 100%;
border-bottom: 2px solid rgb(1, 1, 27);
}
.submission-options-nav{
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
}
.submission-options-auth{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 25px;
}
.auth-link{
    color: rgb(45, 43, 43);
    cursor: pointer;
}
/* Main Body */
.submission-options-main-container{
    width: 100%;
    background-color: rgb(223, 220, 220);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
}
.submission-options-container{
    width: 100%;
    display: flex;
    align-self: flex-start;
    background-color: white;
    flex-direction: column;
    padding: 30px;
}
.submission-options-main-container-info-title{
    font-size: 20px;
}
.submission-options-main-container-info-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: white;
    border-bottom: 4px solid var(--secondary-color);
    box-shadow: 1px 1px 1px 1px rgb(220,220,220);
}
.submission-options-main-container-info-top-wrapper{
    flex-basis: 60%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.submission-options-main-logger-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-basis: 40%;
    width: 100%;
}
.submission-options-main-container-info{
    font-weight: 400;
    font-size: 14px;
}
.info-icon{
    color: var(--secondary-color);
}
.submission-options-main-container-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
/* End of Main */
/* Footer */
.editorial-footer{
    width: 100%;
    background-color:var(--primary-color);
    padding: 25px 10px;
}
.editorial-footer-top{
   display: flex;
   justify-content: space-between;
   align-items: baseline;
   width: 100%;
}
.editorial-footer-top-items{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    width: 100%;
    flex-wrap: wrap;
}
.editorial-footer-top-item{
   line-height: 1;
}
.editorial-footer-top-item-title{
    color: white;
    font-weight: 600;
    font-size: 24px;
}
.editorial-footer-top-item-description{
    color: white;
    font-weight: lighter;
    font-size: 16px;
    text-decoration: underline;
}
.editorial-footer-divider{
    width: 100%;
    height: 2px;
    background-color: white;
    margin-top: 35px;
}
.editorial-footer-top-item-top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}
.editorial-footer-top-item-top-item{
    color: white;
    font-weight: lighter;
    font-size: 16px;
}
.editorial-footer-bottom-bottom{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 18px;
    margin-top: 30px;
}
.bottom-footer-text{
    color: white;
}
.bottom-footer-logo{
    width: 18%;
}
.submission-options-form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex-direction: column;
}
.submission-options-input{
padding: 8px;
border-radius: 4px;
width: 100%;
align-self: flex-start;
border: thin solid gray;
outline: none;
}
.submission-options-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
 text-decoration: none;
 padding: 8px;
    border-radius: 30px;
    background-color: var(--dark-blue-color);
    color: white;
}
.submission-options-btn-orcid{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
 text-decoration: none;
 padding: 8px;
    border-radius: 30px;
    background-color: var(--dark-green-color);
    color: white;
}
.submission-options-btn-title{
 color: white;   
}
.submission-options-btn-icon{
    color: white;
}
.submission-options-submission-icon{
    width: 100%;
    cursor: pointer;
}

.submission-stages-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.submission-stages{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}
.submission-stages-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}
.submission-stage{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-basis: 28%;
    width: 100%;
    padding: 15px;
    height: 150px;
    border: thin solid #b1aaaa;
}
.submission-btn{
    padding: 4px 8px 4px 8px;
    color: var(--secondary-color);
    background-color: #eee;
    border-radius: 4px;
    border: thin solid var(--secondary-color);
    font-weight: lighter;
    font-size: 12px;
}
.submission-info-title{
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-blue-color);
    padding: 10px;
}
.submission-select{
    font-size: 10px;
    padding: 5px;
}
.submission-list-item{
    list-style-type: lower-roman;
    color: rgb(162, 11, 11);
    font-weight: 400;
    padding: 20px;
}
.submission-stage-input{
    width: 100%;
    font-size: 10px;
}
.submission-stage-text-area{
    width: 100%;
    height: 100%;
    padding: 5px;
    color: gray;
    font-size: 12px;
}

@media only screen and (max-width:400px){
    .submission-options-main-container{
        padding: 0;
    }
    .submission-stages-list{
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        line-height: 1;
        margin-bottom: 20px;
    }
    .submission-list-item{
        list-style-type: unset;
        color: var(--dark-blue-color);
        font-weight: 400;
        padding: 5px;
    }
    .submission-stages-container{
        flex-direction: column;
    }
    .submission-stage{
        flex-basis: 100%;
    }
}
.submission-options-main-logger{
    font-size: 12px;
    color: var(--secondary-color);
    font: bold;
    padding: 10px;
    border-radius: 4px;
    border: thin solid var(--secondary-color);
    text-align: right;
}
.submission-options-main-logout{
    font-size: 12px;
    color: var(--secondary-color);
    font: bold;
    padding: 10px;
    border-radius: 4px;
    border: thin solid var(--secondary-color);
    text-align: right;
    cursor: pointer;
}

/* Mobile View */
@media only screen and (max-width:500px) {
    .submission-options-main-container-info-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        gap: 10px;
        width: 100%;
        background-color: white;
        border-bottom: 4px solid var(--secondary-color);
        box-shadow: 1px 1px 1px 1px rgb(220,220,220);
    }
    .submission-options-main-container-info-top-wrapper{
        gap: 10px;
    }
    .submission-options-main-container-info{
        font-weight: lighter;
        font-size: 12px;
    }
}
