*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.editorial-register-main{
    width: 100%;
    background-color: white !important;
}
.editorial-pre-register-site-body{
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 80%;
margin: auto;
background-color: white !important;
flex-wrap: wrap;
gap: 35px;
margin-top: 50px;
}
.editorial-preregister-site-body-left{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-basis: 25%;
width: 100%;
border-right: thin solid rgb(224, 214, 214);
padding-right: 30px;
height: 70vh;
position: relative;
padding: 10px;
}

/* .editorial-register-site-body-left{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
} */
.pre-register-info{
    font-size: 14px;
    /* font-weight: lighter; */
}
.pre-register-btn-group{
    display: flex;
    align-items: center;
    width: fit-content; 
    gap: 15px;  
    padding: 10px;
}
.pre-register-form-btn{
    padding: 4px;
    border: thin solid gray;
    color: var(--pale-blue-color);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: auto !important;
    width: fit-content; 
    border-radius: 5px;
    box-shadow: 1px 1px 0px 0px gray;
}
.pre-register-btn{
    padding: 4px;
    border: thin solid gray;
    background-color: #eee;
    color: gray;
    font-size: 12px;
    font-weight: lighter;
    box-shadow: 1px 1px 0px 0px gray;
}
.editorial-register-site-body-left-title{
    font-size: 16px;
    font-weight: 400;  
    text-align: left; 
}
/* .editorial-register-site-body-left-content{
    font-size: 10px;
    line-height: 1.4;
} */
.pre-register-text{
    color: gray;
    font-size: 12px;
    font-weight: 400;   
}
.pre-register-input-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}
.pre-register-text-input-wrapper{
    flex-basis: 35%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pre-register-required-text{
    color: rgb(171, 19, 19);
    font-size: 12px;
    font-weight: 400;     
    text-align: right;
}
/* .pre-editorial-login-input{
    flex-basis: 64%;
    width: 100%;
} */
.pre-editorial-login-input:focus{
    border: none;
}
.pre-register-input-inner-wrapper{
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 15px;
    border-bottom: thin solid rgb(154, 10, 10);
    padding-bottom: 50px;
    margin-top: 20px;
}
.pre-register-input-and-text-wrapper{
    flex-basis: 85%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}
.pre-register-btn-container{
    flex-basis: 15%;
    width: 100%;
    display: flex;
    align-items: center;
}
.pre-register-info-wrapper{
    border-bottom: thin solid rgb(154, 10, 10);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.pre-register-form-icon{
    border-radius: 50%;
}
.pre-register-form-icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lemon-color);
    border-radius: 50%;
    height: 25px;
    width: 25px;
}
.pre-register-form-icon-i{
color: white;
font-weight: bold;
font-size: 18px;
}
.pre-register-form-icon-d{
    color: white;
    font-weight: bold;
    font-size: 12px;
}
.pre-register-text-caption{
    font-size: 12px;   
    padding: 10px;
    text-align: left;
    align-self: flex-start;
}
.success-message{
    padding: 20px;
    background-color: white;
    color: var(--secondary-color);
    font-size: 12px;
    font-weight: lighter;
}
.error-message{
    padding: 20px;
    background-color: white;
    color: rgb(167, 20, 20);
    font-size: 12px;
    font-weight: lighter;
}
/* .pre-editorial-login-input{
    font-weight: lighter;
    font-size: 12px;
    padding: 5px;
} */

/* Mobile View */
@media only screen and (max-width:1000px) {
    .editorial-register-main{
        /* margin-bottom: 20px !important; */
    }
}

@media only screen and (max-width:500px) {
    .editorial-pre-register-site-body{
        width: 100%;
        padding: 10px;
    }
    .pre-register-input-inner-wrapper{
        gap: 10px;
        flex-direction: column;;
     }
     .pre-register-input-and-text-wrapper{
        gap: 5px;
    }
    .pre-register-input-wrapper{
        gap: 3px;
        flex-direction: column;
    }
    .pre-register-text-input-wrapper{
    flex-basis: 100%;
    justify-content: flex-start;
    align-items: center;
}
.editorial-register-main{
    padding: 20px;
}
}
