*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.editorial-register-main{
    width: 100%;
}
.editorial-step-three-site-body{
display: flex;
justify-content: center;
align-items: flex-start;
width: 100%;
margin: auto;
flex-wrap: wrap;
gap: 35px;
margin-top: 50px;
}
/* .editorial-register-site-body-left{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-basis: 25%;
width: 100%;
border-right: thin solid rgb(224, 214, 214);
padding-right: 30px;
height: 70vh;
position: relative;
} */
.editorial-step-three-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;
    width: 100%;
    background-color: var(--light-gray-bg);
    border: thin solid gray;
    position: relative;
    padding: 30px;
    max-height: fit-content;
    margin-bottom: 40px;
}
.step-three-text-method-top{
    font-size: 10px;
    font-weight: lighter;
    color: #0a0dca;
    padding: 0;
}
.step-three-text-method{
    font-size: 9px;
    font-weight: bold;
    color: #000;
    padding: 0;
}
/* .editorial-register-site-body-left{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
} */
.step-three-info{
    font-size: 14px;
    padding: 10px;
    text-align: justify;
}
.step-three-btn-group{
    display: flex;
    align-items: center;
    width: fit-content; 
    gap: 15px;  
    padding: 10px;
}
.step-three-form-btn,.step-three-form-btn-disabled{
    padding: 4px;
    border: thin solid gray;
    color: var(--pale-blue-color);
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content; 
    border-radius: 5px;
    box-shadow: 1px 1px 0px 0px gray;
}
.step-three-form-btn-disabled{
    background-color: rgb(201, 192, 192);
}
.step-three-btn{
    padding: 4px;
    border: thin solid gray;
    background-color: #eee;
    color: gray;
    font-size: 12px;
    font-weight: lighter;
    box-shadow: 1px 1px 0px 0px gray;
}
.editorial-register-site-body-left-title{
    font-size: 16px;
    font-weight: 400;  
    text-align: left; 
}
/* .editorial-register-site-body-left-content{
    font-size: 12px;
    font-weight: lighter;   
} */
.step-three-text{
    color: gray;
    font-size: 12px;
    font-weight: 400;   
}
.step-three-input-inner-wrapper{
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 15px;
    border-bottom: thin solid rgb(154, 10, 10);
    padding-bottom: 50px;
}
.step-three-input-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}
.step-three-text-input-wrapper{
    flex-basis: 35%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.step-three-required-text{
    color: rgb(171, 19, 19);
    font-size: 12px;
    font-weight: 400;     
    text-align: right;
}
.step-three-login-input{
    flex-basis: 64%;
    width: 100%;
}
.step-three-login-input:focus{
    border: none;
}
.step-three-input-and-text-wrapper{
    flex-basis: 85%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}
.step-three-btn-container{
    width: fit-content !important;
    display: flex;
    align-items: center;
}
.step-three-info-wrapper{
    border-bottom: thin solid rgb(154, 10, 10);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.step-three-btn-radio-inner-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.step-three-btn-radio-wrapper{
    display: flex;
    justify-content:center;
    align-items: baseline;
    align-self: flex-start;
    width: 100%;
    gap: 5px;
}
.step-three-form-icon{
    border-radius: 50%;
}
.step-three-form-icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lemon-color);
    border-radius: 50%;
    height: 25px;
    width: 25px;
}
.step-three-form-icon-i{
color: white;
font-weight: bold;
font-size: 18px;
}
.step-three-form-icon-d{
    color: white;
    font-weight: bold;
    font-size: 12px;
}
.step-three-text-caption{
    font-size: 12px;   
    padding: 10px;
    text-align: left;
    align-self: flex-start;
}
.success-message{
    padding: 20px;
    background-color: white;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: lighter;
}
.error-message{
    padding: 20px;
    background-color: white;
    color: rgb(167, 20, 20);
    font-size: 12px;
    font-weight: lighter;
}
.step-three-login-input{
    font-weight: lighter;
    font-size: 12px;
    padding: 5px;
}
.step-three-right-wrapper{
width: 100% !important;
}
.step-three-input-value{
    color: var(--secondary-color);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    align-self: flex-start !important;
}
/* Mobile View */
@media only screen and (max-width:500px) {
    .step-three-required-text{
        color: rgb(171, 19, 19);
        font-weight: 400;     
        text-align: left;
    }
    .step-three-input-value{
        font-size: 10px !important;
        font-weight: 400 !important;
        text-align: left !important;
        align-self: flex-start !important;
    }
    .step-three-text-method{
        /* font-size: 9px !important;
        font-weight: bold !important;
        color: #000 !important;
        padding: 0 !important;
        background-color: red !important; */
    }
    .editorial-step-three-site-body{
        width: 100%;
        padding: 10px;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            margin: auto;
            flex-wrap: wrap;
            gap: 15px;
            margin-top: 30px;
    }
    /* .editorial-register-site-body-left{
    flex-basis: 100%;
    width: 100%;
    padding-right: 30px;
    height: 70vh;
    } */
    .editorial-step-three-form{
        flex-basis: 100%;
        width: 100%;
        padding: 10px;
    }
    /* .editorial-register-site-body-left{
        flex-basis: 100% !important;
    } */
    .step-three-input-inner-wrapper{
        gap: 10px;
        flex-direction: column;
        margin-top: 30px;
     }
     .step-three-input-and-text-wrapper{
        gap: 1px;
        align-items: center !important;
        
    }
    .step-three-input-wrapper{
        gap: 1px;
    }
    .step-three-text-input-wrapper{
    flex-basis: 100%;
    justify-content: flex-start;
    align-items: center;
}
}
.editorial-login-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  flex-direction: column;
}
.editorial-login-header-bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
    background-color: #cddde0;
    padding: 10px;
  }
.anouncement{
    color: white;
    align-self: flex-end;
    background-color: var(--red-color);
    font-size: 12px;
    padding: 5px 15px;
}
