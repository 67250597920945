* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.editotrial-login-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.editotrial-login-container-top{
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.editotrial-login-main-left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  flex-basis: 25%;
  padding: 20px;
}
.editotrial-login-main-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: auto;
  border: thin solid rgb(167, 157, 157);
  padding: 20px;
  position: relative;
  background-color: var(--light-gray-bg);
  width: 100%;
}
.editotrial-login-form-title{
  font-size: 12px;
  font-weight: 500;
}
.editorial-login-main-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto;
}
.editorial-login-main-input-caption {
  flex-basis: 20%;
  width: 100%;
  overflow: hidden !important;
  color: var(--primary-color);
  /* font-weight: lighter; */
  /* font-size: 14px; */
}
.editorial-login-main-input {
  flex-basis: 85%;
  width: 100%;
  padding: 5px;
  font-size: 10px;
  border: thin solid #000;
  /* font-weight: lighter; */
}
.editorial-login-warning-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.editorial-login-warning-content {
  color: var(--secondary-color);
  padding: 0;
  text-align: justify;
  padding: 4px;
  font-size: 12px;
  font-weight: bold;
}
.editorial-login-main-btn {
  padding: 4px 8px 4px 8px;
  font-size: 12px;
  color: var(--primary-color);
  border: thin solid gray;
  border-radius: 4px;
}
.editorial-login-main-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.editorial-alternate-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1;
}
.editorial-login-main-right{
  flex-basis: 20%;
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.editorial-alternate-login-caption {
  font-size: 12px !important;
  color: var(--secondary-color) !important;
  padding: 5px !important;
  text-decoration: none !important;
  font-weight: bold !important;
}
.editorial-alternate-login-caption-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow: hidden !important;
  gap: 0;
  height: fit-content;
  width: 100%;
}
.editorial-alternate-login-caption-right {
  font-size: 12px;
  color: gray;
  line-height: 1;
  font-weight: normal;
}
.editorial-login-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.editorial-login-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 70px;
  padding: 10px;
  background-color: var(--primary-color);
}
.editorial-login-header-left {
  /* flex-basis: 50%; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: fit-content !important;
}
.editorial-login-header-left-left-tilte {
  color: white;
  font-weight: 600;
  padding: 4px;
  background-color: var(--pale-blue-color);
  border-radius: 50%;
}
.editorial-login-header-left-right-title {
  color: white;
  font-weight: 400;
}
.editorial-login-header-right {
  flex-basis: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.editorial-login-header-right-title {
  color: white !important;
  font-weight: lighter !important;
  font-size: 14px !important;
  padding: 10px !important;
  text-decoration: none !important;
  background-color: var(--primary-color) !important;
  border: none !important;
}
.editorial-login-down-menu {
  width: 100%;
  padding: 15px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editorial-login-down-menu-items {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  color: var(--primary-color);
}
li {
  list-style: none;
}
.editorial-login-down-menu-selection {
  outline: none;
  color: var(--pale-blue-color);
  border: none;
  background-color: #eee;
}
.editorial-login-down-menu-select-container {
  width: 100%;
}
.editorial-login-down-menu-link {
  cursor: pointer;
  color: #000 !important;
}
.editotrial-login-main {
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  flex-basis: 50%;
  width: 100%;
  padding: 20px;
  box-shadow: 1px 1px 1px 1px #eee;
}
.editotrial-login-main-info {
  font-size: 12px;
  /* font-weight: lighter; */
  position: absolute;
  top: 5;
  right: 10;
}

.editorial-alternate-login-caption-policy {
  font-size: 12px;
  color: var(--secondary-color);
  /* font-weight: lighter; */
}
.editorial-login-warning-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  padding: 10px;
}
.editorial-login-warning-content-bold-red {
  color: red;
  font-weight: bolder;
  word-spacing: 0px;
  font-size: 12px;
}
.editorial-login-warning-inner-wrapper {
  width: 100%;
  display: inline;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.3;
  background-color: rgb(239, 232, 232);
  gap: 3px;
  padding: 1px 15px 1px 15px;
}
.editorial-login-warning-bold-black {
  font-weight: bold;
  font-size: 12px;
}
.editorial-login-main-right-link{
  font-size: 12px;
  text-decoration: none;
}
/* Mobile View */
@media only screen and (max-width: 500px) {
  .editorial-login-main-right-link{
  font-size: 11px;
}
  .editorial-alternate-login-caption {
    /* font-size: 10px !important; */
    padding: 2px !important;
  }
  .editotrial-login-container-top{
    flex-direction: column;
    padding: 0px;
  }
  .editotrial-login-main-left{
    flex-basis: 100%;
    padding: 5px;
  }
  .editorial-alternate-login-caption-right{
    font-size: 10px;
  }
  .editotrial-login-main {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
    box-shadow: 1px 1px 1px 1px #eee;
  }
  .editotrial-login-main-form {
    width: 100%;
  }
  .editorial-login-main-input-caption {
    flex-basis: 20%;
    width: 100%;
    color: var(--primary-color);
    /* font-weight: lighter; */
    /* font-size: 10px; */
    line-height: 1;
  }
  .editorial-login-main-input-wrapper {
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 2px;
  }
  .editorial-login-main-input {
    flex-basis: 100%;
    padding: 5px;
  }
  .editorial-login-main-btn-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 4px;
    flex-wrap: wrap;
  }
  .editorial-login-main-btn{
    /* font-size: 8px; */
  }
  .editorial-login-down-menu {
    width: 100%;
    padding: 15px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .editorial-login-down-menu-items {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    color: var(--primary-color);
    background-color: #eee;
  }
  .editorial-login-down-menu-selection {
    outline: none;
    color: var(--pale-blue-color);
    border: none;
  }
  .editorial-login-down-menu-select-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .editorial-login-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    height: auto;
    padding: 10px;
    gap: 20px !important;
    background-color: var(--primary-color);
  }
  .editorial-login-header-left {
    flex-basis: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    width: 100% !important;
  }
  .editorial-login-header-right {
    flex-basis: 50% !important;
    justify-content: flex-end !important;
    }
  .editorial-login-header-left-right-title {
    color: white !important;
    font-size: 10px !important;
    font-weight: lighter !important;
    padding-left: 5px !important;
    line-height: 1 !important;
  }
  .editorial-login-header-right-separator{
    color: white !important;
    font-weight: lighter !important;
    font-size: 8px !important;
  }
  .editorial-login-header-right-title {
    color: white !important;
    font-weight: lighter !important;
    font-size: 8px !important;
    padding: 2px !important;
  }
}
