
.pagination-container-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: auto !important;
}
.pagination-container-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: auto !important;
}
@media only screen and (max-width:500px){
    .user-auth-wrapper-card{
        width: 100% !important;
    }
   
        .confirmation-body-top{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 1.2 !important;
            overflow: hidden !important;
        }
        .confirmation-body-inner-top{
            gap: 6px;
            font-size: 8px !important;
            overflow: hidden !important;
            margin-bottom: 5px;
        }
        .confirmation-body-inner-bottom{
            background-color: var(--light-bg);
            padding: 5px !important;
            line-height: 1.2 !important;
        }
        .confirmation-body-top-content{
            font-size: 8px;
            
        }
/*         
        .confirmation-body-bottom table{
            min-width: 100% !important;
            background-color: var(--light-bg);
        } */
        /* .confirmation-body-bottom thead{
            color: white;
            padding: 10px !important;
            background-color: var(--primary-color);
        }
        .confirmation-body-bottom td{
            font-size: 14px !important;
        } */
        /* .table-head-icons{
            color: white !important;
            display: flex;
            gap: 10px;
        } */
         /* td{
            padding: 5px !important;
        } */
        /* .table-card{
            width: fit-content;
            padding: 20px;
            background-color: white;
            box-shadow: 1px 1px 1px 1px #333;
            position: absolute;
            top: 40px;
        }
        .table-card li{
            cursor: pointer;
            color: var(--secondary-color) !important;
            font-weight: bold;
        } */
        .pagination-container-top{
            width: 185% !important;
        }
        .pagination-container-bottom{
            width: 185% !important;
        }
}
.confirmation-body-top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.confirmation-body-inner-top{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    color: var(--primary-color);
}
.confirmation-body-inner-bottom{
    background-color: var(--light-bg);
    padding: 10px;
    line-height: 1;
}

.confirmation-body-inner-bottom p{
    margin: 10px !important;
}
.confirmation-body-top-content{
    font-size: 12px;
    
    overflow: hidden !important;
    text-align: justify;
}

/* .confirmation-body-bottom table{
    width: 100% !important;
    background-color: var(--light-bg);
} */
/* .confirmation-body-bottom thead{
    color: white;
    padding: 20px !important;
    background-color: var(--primary-color);
    font-size: 10px !important;
} */
.table-head-icons{
    /* color: white !important;
    display: flex;
    gap: 10px; */
 }
/* .confirmation-body-bottom tbody{
    padding: 10px;
    background-color: var(--light-bg);
    border: none !important;
} */
.table-card{
    width: fit-content;
    padding: 20px;
    background-color: white;
    box-shadow: 1px 1px 1px 1px #333;
}
.table-card li{
    cursor: pointer;
    color: var(--secondary-color) !important;
    font-weight: bold;
}
.success,.error{
    font-weight: 400;
    line-height: 1.2;
    padding: 10px;
    text-align: center;
}
.success{
    color: var(--primary-color);
}
.error{
    color: var(--red-color);
}
table{
    margin-bottom: 15px !important;
    margin-top: 30px !important;
}
.pagination-item{
    display: flex;
    align-items: center;
    line-height: 1.2;
    gap: 5px;
}
.pagination-left{
    font-size: 12px;
    text-align: left;
}
.pagination-right{
    font-size: 12px;
    text-align: right;
}