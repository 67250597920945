.editorial-overview-main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden !important;
}
.editorial-overview-body{
padding: 20px;
width: 100%;
display: flex;
justify-content: space-around;
align-items: baseline;
}
.editorial-overview-body-left{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
box-shadow: 1px 0px 0px 0px rgb(220, 220, 220);
flex-basis: 25%;
height: 80vh;
line-height: 1.6;
}
.editorial-overview-body-right{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
flex-basis: 60%;
}
.editorial-overview-sub{
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
width: 100%;
}
.editorial-overview-inner{
    display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
width: 100%;
padding-left: 40px !important;
}
.editorial-overview-title{
    font-size: 20px !important;
    font-weight: 600;
}
.editorial-overview-inner-child,.editorial-overview-inner-link,.editorial-overview-inner-value{
color: gray;
font-size: 16px !important;
font-weight: normal;
}
.editorial-overview-inner-link{
    color: var(--secondary-color) !important;
    text-decoration: none;
}
.editorial-overview-inner-value{
    color: var(--lemon-color);
    padding: 5px;
}
.editorial-overview-body-left-title{
font-size: 16px !important;
font-weight: 600 !important;
}
.editorial-overview-body-left-link{
font-size: 16px !important;
font-weight: 500;
color: var(--secondary-color) !important;
text-decoration: none;
}
.editorial-overview-inner-wrapper{
    display: inline-block;
}
.editorial-overview-inner-wrapper a{
    text-decoration: none;
    color: var(--secondary-color);
}
.auth-wrapper{
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    gap: 20px;
}
.user-auth{
    color: var(--primary-color);
    font-size: 24px;
    cursor: pointer;
}
/* Mobile View */
@media only screen and (max-width:500px) {
    .editorial-overview-body-left{
        padding-right: 10px;
        margin-left: -10px;
        flex-direction: column;
            box-shadow: 1px 0px 0px 0px rgb(220, 220, 220);
            flex-basis: 40%;
            gap: 5px;
        }
    .editorial-overview-body-left-title{
        /* font-size: 10px; */
        font-weight: 600;
        }
        .editorial-overview-body-left-link{
        /* font-size: 9px; */
        /* font-weight: 100; */
        line-height: 1.3;
        color: var(--secondary-color);
        }
.editorial-overview-inner-child,.editorial-overview-inner-link,.editorial-overview-inner-value{
    color: gray;
    /* font-size: 10px; */
    font-weight: lighter;
    /* line-height: 1.3; */
}
.editorial-overview-title{
    /* font-size: 12px; */
    font-weight: 600;
}
.editorial-overview-inner{
    display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
line-height: 1;
width: 100%;
}
}
.anouncement{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.user-auth-wrapper{
    cursor: pointer;
}
.editorial-login-header-bottom{
    width: 100%;
}
.user-auth-wrapper-card{
    padding: 20px;
    color: #333;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
}
.user-auth-wrapper-card-items{
    color: #333;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    width: 80%;
}
.user-auth-wrapper-card-item{
    /* font-size: 12px; */
    font-weight: 500;
    padding: 10px 0px;
    text-transform: capitalize;
}
.user-auth-wrapper-card-item-anchor{
    /* font-size: 10px; */
    font-weight: 500;
    text-transform: capitalize;
    padding: 10px 0px;
    border-bottom: none !important;
    text-decoration: none;
}
.user-auth-wrapper-card-item-anchor:hover{
    text-decoration: underline;
}
@media only screen and (max-width:500px){
    .user-auth-wrapper-card{
        width: 100% !important;
    }
    .incomplete-modal{
        width: 100% !important;
    }
    .incomplete-modal-container{
        gap: 30px;
    }
    .incomplete-modal-btn-group{
        width: 70% !important;
    }
    .incomplete-modal-btn{
    margin-top: 10px;
    }
    .incomplete-modal-text{
        font-size: 14px;
    }
}
.incomplete-modal{
    width: 50%;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 1px rgb(220,220,220);
    min-height: 200px;
    max-height: fit-content;
    margin: 40px auto;
    background-color: white;
}
.incomplete-modal-container{
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.incomplete-modal-btn-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}
.incomplete-modal-btn{
padding: 10px 14px;
border: thin solid gray;
color: white;
margin-top: 20px;
background-color: var(--primary-color);
font-weight: 400;
display: flex;
align-items: center;
gap: 5px;
width: fit-content; 
border-radius: 5px;
box-shadow: 1px 1px 0px 0px gray;
}
.incomplete-modal-text{
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-color);
}