*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.menu-toggler-container{
    display: none;
}
.editorial-login-down-menu-min{
    display: none;
}
.submission-main-container{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.submission-item-body{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    gap: 30px;
    width: 100%;
    padding-right: 25%;
}
.submission-items-left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 30px !important;
    width: 100%;  
    padding: 20px;
    box-shadow: 1px 1px 1px 0px rgb(220, 220, 220);
}
/* Progress Section */
.progress-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
    padding: 20px;
    padding-right: 25%;
    position: relative;
}
.connector,.active-connector{
    height: 1px;
    background-color: rgb(194, 194, 200);
}
.connector{
    background-color: rgb(194, 194, 200);
}
.active-connector{
    background-color: var(--primary-color);
}
.level-point{
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    background-color: transparent;
    color: #333;
    z-index: 7000;
    position: absolute;
    bottom: 13px;
    cursor: pointer;
    color: transparent;
}
.level-caption{
    margin-top: 40px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    width: 70px;
    color: #333;
}
.complete-level,.incomplete-level,.default-level,.current-level{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-self: right;
    gap: 25px;
    flex-direction: column;
}
.default-level{
background-color: rgb(255, 255, 255);
border: 2px solid rgb(217, 207, 207);
}
.current-level{
    background-color: var(--dark-blue-color);
    color: white;
}
.complete-level{
    background-color: var(--dark-green-color);
    color: white;
}
.incomplete-level{
    background-color: var(--red-color);
    color: white;
}
.completed-icon{
    font-size:48px !important;
    color:var(--dark-green-color) !important;
    background-color:white !important;
    top:-8 !important;
    left:-5 !important;
    position:absolute !important;
}
.submission-check{
    color: white !important;
    font-weight: bold !important;
    position: absolute !important;
    bottom: 10px !important;
    left: 10px !important;
    padding: 0px;
}
.open-menu{
    display: none;
}
.close-menu{
    display: none;
}
.submission-auth-btn-left-wrapper{
    background-color: var(--primary-color);
    color: white;
    padding: 4px 10px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.submission-auth-btn-group-5{
    align-self: flex-end;
    display: flex;
    gap: 15px;
}
.submission-auth-btn-right-wrapper{
    background-color: var(--primary-color);
    color: white;
    padding: 10px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
@media only screen and (max-width:500px) {
    .submission-auth-btn-right-wrapper{
        background-color: var(--primary-color);
        color: white;
        border-radius: 4px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
    }
    .submission-auth-btn-group-5{
        align-self: center;
        display: flex;
        gap: 10px;
        margin: 5px;
    }
    .progress-container{
        width: 100%;
        margin-bottom: 70px;
        padding: 20px;
        padding-right: 4%;
        position: relative;
        margin-top: 30px !important;
    }
    .connector,.active-connector{
        height: 1px;
        background-color: rgb(194, 194, 200);
    }
    .connector{
        background-color: rgb(194, 194, 200);
    }
    .active-connector{
        background-color: var(--primary-color);
    }
    .level-point{
        width: 50px;
        height: 50px;
        padding: 10px;
        border-radius: 50%;
        background-color: transparent;
        color: #333;
        z-index: 7000;
        position: absolute;
        bottom: 13px;
        cursor: pointer;
        color: transparent;
    }
    .level-caption{
        margin-top: 40px;
        font-size: 7px;
        font-weight: 500;
        line-height: 1.1;
        width: 30px;
        padding-right: 30px;
        color: #333;
    }
    .complete-level,.incomplete-level,.default-level,.current-level{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-self: right;
        gap: 25px;
        flex-direction: column;
    }
    .default-level{
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(217, 207, 207);
    }
    .current-level{
        background-color: var(--dark-blue-color);
        color: white;
    }
    .complete-level{
        background-color: var(--dark-green-color);
        color: white;
    }
    .incomplete-level{
        background-color: var(--red-color);
        color: white;
    }
    .completed-icon{
        font-size:48px !important;
        color:var(--dark-green-color) !important;
        background-color:white !important;
        top:-8 !important;
        left:-5 !important;
        position:absolute !important;
    }
    .submission-check{
        color: white !important;
        font-weight: bold !important;
        position: absolute !important;
        bottom: 8px !important;
        left: 5px !important;
        padding: 0px;
    }
    .submission-arrow{
        position: absolute !important;
        width: 1em !important;
        height: 1em !important;
        top: 22px !important;
        left: 4px !important;
        padding: 0px;
    }
} 
/* End of Progress Section */

.submission-auth{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 25px;
box-shadow: 1px 2px 2px rgba(4, 0, 18, 0.505);
}
.auth-title{
    font-size: 16px; 
background-color: var(--dark-blue-color);
width: 100%;
border-radius: 5px;
padding: 4px 20px;
color: white;
margin-bottom: 0;
box-shadow: 2px 2px 2px 2px rgb(220, 220, 220);
}
.submission-auth-main-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px;
    width: 100%;
    flex-direction: row;
 }
.submission-auth-container{
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    flex-basis: 80%;
    width: 100%;
}
.submission-auth-main-inner{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    flex-basis: 20%;
    width: 100%;
    padding: 10px;
    line-height: 1.3;
}
.submission-auth-main-inner li{
font-weight: bolder;
color: var(--dark-blue-color);
font-style: italic;
}
.submission-auth-main-inner li a{
    font-weight: bold;
    color: var(--secondary-color);
    
}
.submission-auth-container-form{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 0;
width: 100%;
gap: 20px;
}
.submission-auth-container-selection-container{
    box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
    padding: 5px 30px;
    width: 100%;
    border-radius: 5px;
}
.orcid-display{
    box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
    padding: 5px 30px;
    width: 100%;
    border-radius: 5px;
}
.submission-auth-container-selection{
font-size: 12px;
font-weight: 100;
align-self: flex-start;
border-radius: 0;
}
.submission-auth-container-selection option{
    font-size: 12px;
    font-weight: 100;
}
.submission-auth-container-btn{
align-self: flex-end;
display: flex;;
justify-content: space-between;
align-items: center;
gap: 30px;
color: white;
background-color: #c9bfbf;
padding: 10px 10px 10px 30px;
border-radius: 5px;
border: none;
box-shadow: 1px 1px 1px 1px var(--light-bg);
}
.submission-auth-container-btn-active{
    align-self: flex-end;
    display: flex;;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    color: white;
    background-color: var(--primary-color);
    padding: 10px 10px 10px 30px;
    border-radius: 5px;
    border: none;
}
.show-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.show-text-guide{
    color:var(--red-color);
    font-style:italic;
    font-size: 12px;
    font-weight: bold;
}
.show-text-guide-icon-down{
    transform: rotate(90deg);
    cursor: pointer;
    font-size: 10px;
}
.show-text-guide-icon-right{
    cursor: pointer;
    font-size: 10px;
}
.submission-auth-uploader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    background-color: var(--light-bg);
}
.submission-auth-uploader-item{
    font-size: 12px;
}
.submission-auth-uploader-inner-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.submission-auth-uploader-inner-icon{
width: 100px;
}
.submission-auth-uploader-browse-item{
    background-color: var(--dark-blue-color);
    box-shadow: 1px 1px 1px 1px gray;
    color: white;
    padding: 6px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    border-radius: 4px;
}
.submission-auth-uploader-drag-item{
    font-size: 12px;
}
.auth-top-guide{
position: absolute;
color: var(--secondary-color);
font-size: 10px;
top: -15px;
right: 0;
}
.submission-auth-container-2{
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: relative;
    flex-direction: column;
    flex-basis: 80%;
    width: 100%;
}
.file-input{
    display: none;
}
.file-input-show{
    padding: 4px;
    font-size: 12px;
    font-weight: lighter;
}
.submission-auth-uploader-container-inner-2{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}
.editorial-login-down-menu-selection-inner-2{
    width: fit-content;
    font-size: 12px;
    font-weight: lighter;
   width: 100% !important;
}
.submission-auth-uploader-container-title-2 {
    font-size: 12px;
}
.show-text-guide-2 {
    color:var(--primary-color);
    font-style:italic;
    font-size: 12px;
    font-weight: bold;
}
.checked-link {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10px;
    color: var(--dark-green-color) !important;
    font-size: 12px !important;
    font-weight: lighter !important;
}
.blinded-manuscript{
    color: var(--red-color);
    list-style: inside;
}
.submission-auth-main-inner-2{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    flex-basis: 20%;
    width: 100%;
    padding: 10px;
    line-height: 1.3;
}
.submission-auth-main-inner li,.submission-auth-main-inner-2 li{
    font-weight: bolder;
    color: var(--dark-blue-color);
    font-style: italic;
}
.submission-auth-main-container-bottom{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    padding: 20px;
    width: 100%;
}
.submission-auth-main-container-bottom-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;   
}
.submission-auth-main-container-bottom-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    flex-basis: 80%;
    width: 100%;    
    gap: 20px;  
}
.submission-auth-main-container-bottom-right{
    display: flex;   
    align-items: center;
    flex-basis: 20%;
    width: 100%;
    gap: 25px;
    align-self: flex-end;
    margin-top: 0px;
}
.submission-auth-main-container-bottom-caption{
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-color);
}
.submission-auth-main-container-bottom-input{
    font-size: 12px;
    font-weight: lighter;
    width: 100px;
}
.submission-auth-main-container-bottom-input[type='file']{
color: rgba(0, 0, 0, 0);
}
.table-head-td{
    font-size: 10px;
    font-weight: 500;
}
.table-body-td{
    font-size: 9px;
    font-weight: 100;
    text-align: left !important;
}
.bottom-btn{
    background-color: var(--primary-color);
    color: rgb(237, 228, 228);
    font-size: 12px;
    padding: 5px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    width: 130px;
}
.disabled-bottom-btn{
    background-color: gray;
    color: rgb(237, 228, 228);
    font-size: 12px;
    padding: 5px;
    border-radius: 3px;
    border: none;
}
.clear-check{
    font-size: 12px;
    font-weight: lighter;
    color: var(--secondary-color);
    cursor: pointer;
    text-decoration: none;
}
.submission-auth-main-container-bottom-title{
    font-size: 12px;
    font-weight: lighter;
}
.submission-auth-main-container-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

/* .submission-auth-main-container-bottom-top table{
    padding: 20px;
    width: 100%;   
    box-shadow: none;
} */
.submission-auth-main-container-bottom-top table input[type='radio']{
background-color: blue;
}
th td{
    font-size: 12px;
    font-weight: lighter;
}
tbody td{
    font-size: 12px;
    font-weight: lighter;
    border-top: 1px solid #333;
}
tbody td{
    border-bottom: 1px solid #333;
    border-top: 1px solid #333;
}
.submission-auth-main-container-bottom-bottom-back-btn{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
    padding: 10px;
    gap: 5px;
    background-color: var(--primary-color);
    color: white;
    font-weight: 400;
    font-size: 12px;
    border: none;
    border-radius: 4px;
}
.submission-auth-main-container-bottom-bottom-proceed-btn{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
    padding: 10px;
    gap: 10px;
    background-color: var(--primary-color);
    color: white;
    font-weight: 500;
    font-size: 16px;
    border: none;
    border-radius: 4px;
}
.submission-auth-main-container-bottom-bottom{
 display: flex;   
 justify-content: flex-end;
 align-items: center;
 width: 100%;
 gap: 25px;
 align-self: flex-end;
}
.submission-auth-container-3{
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    gap: 10px;
    flex-basis: 80%;
    width: 100%;
}
.submission-auth-container-3-top{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 1px 10px;
    border-radius: 5px;
    width: 100%;
    background-color: var(--primary-color);
    box-shadow: 1px 1px 1px 1px gray;
}
.submission-auth-container-3-top-item,.submission-auth-container-3-top-item-open{
    font-size: 16px;
    font-weight: bold;
    color: white;
}
.submission-auth-container-3-top-item-close{
    font-size: 16px;
    font-weight: bold;
    color:rgb(0, 153, 255);
}
.submission-auth-container-3-bottom{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 40px;
    gap: 20px;
}
.submission-auth-container-3-bottom-item-content{
    text-align: justify;
    font-weight: lighter;
    font-size: 12px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    margin-top: 20px;
    padding: 10px;
}
.submission-auth-container-opposed{
    width: 100%;
}
.submission-auth-container-opposed-title-container{
    background-color: var(--primary-color);
    width: 100% !important;
    padding: 1px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.submission-auth-container-opposed-title{
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: white;
}
.submission-auth-container-3-bottom-opposed-list{
    background-color: var(--light-bg);
}
.submission-auth-container-opposed-title-container-right{
    color: white;
    font-size: 12px;
    font-size: bold;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.submission-auth-container-3-bottom-item-content-bottom{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}
.submission-auth-container-3-bottom-item-content-bottom-left{
    flex-basis: 30%;
    background-color: var(--primary-color);
    color: white;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.submission-auth-container-3-bottom-item-content-bottom-right{
    flex-basis: 70%;
    background-color: rgb(202, 215, 218);
    color: rgb(202, 215, 218);
    padding: 0px;
    height: 30px;
}
.submission-auth-container-3-bottom-item-content-down{
    text-align: justify;
    font-weight: lighter;
    background-color: var(--light-bg);
    font-size: 12px;
    padding: 10px;
}
/* Modal Section */
.modal-main{
    width: 100%;
    height: calc(100vh + 200px);
    background: transparent;
    z-index: 300;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}
.modal-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 50%;
    background-color: white;
    height: 55%;
    margin: 20% 14% 16% 200px !important;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 2px gray;
    padding: 3px;
}
.modal-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 4px;
    padding: 1px 10px;
}
.modal-top-left-item{
    font-size: 12px;
    color: white;
    font-weight: bold;
}
.modal-top-right-item{
    font-size: 12px;
    color: gray;
    font-weight: bolder;
    padding-top: 8px;
    background-color: rgb(220, 205, 205);
    padding: 5px 5px 0px 5px;
    border-radius: 4px;
    margin: 2px;
    cursor: pointer;
}
.modal-middle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
}
.modal-middle-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.modal-middle-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: var(--secondary-color);
}
.modal-form{
 display: flex;   
 justify-content: center;
flex-direction: column;
 align-items: center;
 gap: 5px;
 width: 100%;
}
.modal-form-inner{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 50%;
}
.modal-caption{
    font-size: 12px;
    font-weight: bold;
}
.modal-form-info-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    align-self: flex-end;
    gap: 5px;
    width: 50%;
}
.modal-info-caption{
    font-size: 10px;
    font-weight: lighter;
    color: var(--secondary-color);
}
.modal-input{
    padding: 4px;
    font-size: 12px;
    font-weight: lighter;
    color: #333;
    border: thin solid gray;
}
.modal-form-inner-institution{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 180px;
    gap: 10px;
}
.modal-form-inner-reason{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 200px;
    gap: 10px;
}
.modal-input-institution{
    padding: 4px;
    font-size: 12px;
    font-weight: lighter;
    color: #333;
    width: 100%;
    border: thin solid gray;
}
.modal-textarea{
    padding: 4px;
    font-size: 12px;
    font-weight: lighter;
    color: #333;
    width: 250px !important;
    border: thin solid gray;
}
.modal-info-display{
    padding: 10px;
    font-size: 10px;
    font-weight: lighter;
    color: gray;
    position: absolute;
    right: 600px;
    top: 300px;
    background-color: white;
    border: 1px solid gray;
}
.opposers-detail{
    display: flex;
    background-color: white;
    align-items: center;
    padding: 5px;
    justify-content: flex-start;
    gap: 5px;
    border-bottom: 1px solid gray;
}
.opposers-item{
    text-align: justify;
    font-size: 10px;
    font-weight: bold;
    padding: 5px;
}
.opposers-edit-icon{
    width: 20px;
    cursor: pointer;
}
.modal-main-2{
    width: 100%;
    height: calc(100vh + 200px);
    background: transparent;
    z-index: 300;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}
.modal-container-2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 40%;
    background-color: white;
    height: 25%;
    margin: 13% 14% 16% 200px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 2px gray;
    padding: 3px;
}
.modal-middle-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 10px;
}
.modal-middle-down-2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}
.modal-middle-2-content{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    padding: 20px;
    color: var(--primary-color);
}
.delete-btn{
    background-color: var(--light-bg);
    padding: 4px 10px;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px var(--primary-color);
    font-size: 12px;
    font-weight: bold;
}
.-group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.-left-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
    padding: 5px 8px;
    border: none;
    gap: 10px;
    border-radius: 5px;
    background-color: var(--primary-color);
}
.-right-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    width: calc('fit-item' + 100px) !important;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
}
.submission-auth-container-4-bottom{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 40px;
    gap: 20px;
    border: 1px solid gray;
    border-radius: 5px;
}
.submission-auth-container-4-bottom-card{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    border: thin solid gray;
    padding: 20px 10px !important;
}
.submission-auth-container-4-bottom-item-content{
    text-align: justify;
    font-weight: lighter;
    font-size: 12px;
    margin-top: 20px;
    padding: 10px;
}
.submission-auth-container-4-bottom-card-title{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 20px;
}
.submission-auth-container-4-bottom-card-instructions{
    color: var(--secondary-color);
    font-size: 12px;
    font-weight: lighter;
    text-decoration: none;
}
.submission-auth-container-4-bottom-card-required{
    color: var(--red-color);
    font-size: 12px;
    overflow: hidden !important;
    font-weight: bold;
    text-decoration: none;
    line-height: 1.2;
}
.submission-auth-container-4-bottom-card-form-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.submission-auth-container-4-bottom-card-bottom{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 20px;
}
.submission-auth-container-4-bottom-card-title-over{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--red-color);
}
.submission-auth-container-4-bottom-card-form{
    display: flex;
    justify-content: center;
    align-items: center;
}
.submission-auth-container-4-bottom-card-bottom-textarea{
    padding: 10px;
    font-size: 12px;
    font-weight: lighter;
    border: thin solid black;
    color: #333;
}
.submission-auth-container-4-bottom-card-form-content{
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: var(--red-color);
}
.submission-auth-container-4-bottom-card-title{
    font-size: 16px;
}
.submission-auth-container-4-bottom-card-info{
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    overflow: hidden !important;
    line-height: 1.5;
}
.submission-auth-container-4-bottom-card-guide-container{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px !important;
    width: 100% !important;
}
.submission-auth-container-4-bottom-card-instructions{
    color: var(--secondary-color);
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}
.submission-auth-container-4-bottom-card-title-over{
    font-size: 8px;
    font-weight: 500;
    padding-left: 5px;
}
.submission-auth-container-4-bottom-card-guide-container{
    margin-left: 30px;
}
.submission-auth-container-4-bottom-card-guide-item{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    list-style: inside;
    overflow: hidden !important;
    color: black;
}
.submission-auth-container-4-bottom-card-bottom-wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0px;
    flex-direction: column;
}
.submission-auth-container-4-bottom-card-form-bottom{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.submission-auth-container-4-bottom-card-form-bottom-select{
    font-size: 14px;
    font-weight: 500;
    padding: 4px;
    width: 50%;
    border: thin solid black;
}
.ratio-input{
    color: red !important;
}
.submission-auth-container-5-main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.submission-auth-container-5-top{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 1px 10px;
    border-radius: 5px;
    width: 100%;
    color: var(--light-bg);
    background-color: var(--primary-color);
    box-shadow: 1px 1px 1px 1px gray;
}
.submission-auth-container-5-top-closed{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    padding: 1px 10px;
    border-radius: 5px;
    width: 100%;
    background-color: var(--light-bg);
    color: var(--primary-color);
    box-shadow: 1px 1px 1px 1px gray;
}
.submission-auth-main-container-5{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px;
    width: 100%;
    flex-direction: row;
 }
 .-group-5{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2px;
}
.input-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}
.input-wrapper-field{
    padding: 4px;
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    height: 150px;
    color: gray;
}
.input-wrapper-field-abstract{
    padding: 4px;
    font-size: 10px;
    width: 100%;
    color: gray;
}
.input-caption{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: var(--primary-color);
}
.manuscript-form{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.editorial-login-down-menu-link,.editorial-login-down-menu-selection,.editorial-login-down-menu-link{
    cursor: pointer;
    padding: 0px;
}
.editorial-login-down-menu-items{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 10px;
    align-self: flex-start !important;
    padding: 10px !important;
}
.editorial-login-down-menu-link{
    width: 350px !important;
}
.editorial-login-down-menu-selection{
    display: flex;
}
.editorial-login-down-menu-select-container{
    width: 200px;
}
/* Header Mobile View */
@media only screen and (max-width:500px) {
    .submission-auth-container-5-top,.submission-auth-container-5-top-closed{
        padding: 10px 8px;
    }
    .submission-auth-container-3-top-item,.submission-auth-container-3-top-item-open,.submission-auth-container-3-top-item-close{
        font-size:20px !important;
    }
    .table-row{
        width: 100% !important;
    }
    .bottom-btn{
        font-size: 8px;
        padding: 4px;
        width: 100px;
    }
    .disabled-bottom-btn{
        font-size: 10px;
    }
    .submission-auth-container-3-top-item-close,.submission-auth-container-3-top-item-open{
        font-size: 12px;
        font-weight: bold;
    }
    .input-caption{
        font-size: 12px;
        text-align: left;
    }
    .opposers-item{
        text-align: justify;
        font-size: 8px;
        font-weight: 100;
    }
    .opposers-edit-icon{
        width: 20px;
        cursor: pointer;
        padding-right: 0px !important;
    }
    .submission-auth-container-opposed-title-container-right{
        font-size: 10px;
        justify-content: flex-end !important;
        gap: 5px !important;
        width: 100%;
    }
    .submission-auth-container-opposed-title{
        text-align: left;
        font-weight: bold;
        line-height: 1.5;
        font-size: 10px;
        color: white;
    }
    .submission-auth-container-opposed-title-container{
        padding: 1px 8px;
    }
    .submission-auth-container-4-bottom-card-info{
        text-decoration: none;
        overflow: hidden !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.5;
    }
    .submission-auth-container-4-bottom-card-form-bottom-select{
        padding: 1px;
        width: 90%;
        margin: 10px auto !important;
    }
    .submission-auth-container-4-bottom-card-form-bottom-select option{
        padding: 10px;
    }
    .submission-auth-container-4-bottom-card-form-container{
        width: 100%;
    }
    .submission-auth-container-4-bottom-card-required{
        font-size: 10px;
        padding-right: 10px;
    }
    .submission-auth-container-4-bottom-card-bottom{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
    }
    .submission-auth-container-4-bottom-card-guide-item{
        line-height: 1.4;
    }
    .submission-auth-container-opposed{
        width: 100%;
    }
    .submission-item-body{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 5px;
        gap: 10px;
        padding-right: 0px;
    }
.submission-header-right{
        line-height: 1.2 !important;
        display: flex !important;
        justify-content: space-around !important;
        align-items: center !important;
        flex-direction: row !important;
        width: fit-content !important;
        color: white;
}
.submission-header-right-title{
        font-size: 12px !important;
        color: white !important;
        font-weight: 400 !important;
        flex-wrap: nowrap !important;
    }
}
/* Header End of Mobile View */
/* Body section-Mobile View */
@media only screen and (max-width:500px){
    .submission-auth-container-4-bottom{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        gap: 20px;
        border: 1px solid gray;
        border-radius: 5px;
    }
    .submission-auth-container-4-bottom-card-form-bottom{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    }
    .submission-auth-container-4-bottom-card{
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
        border: thin solid gray;
        padding: 5px;
    }
    .submission-auth-container-3-top{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        padding: 1px 10px;
        border-radius: 5px;
        /* width: 100%; */
        background-color: var(--primary-color);
        box-shadow: 1px 1px 1px 1px gray;
    }
    .submission-auth-container-3{
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-direction: column;
        gap: 10px;
        flex-basis: 50% !important;
        width: 100%;
        padding: 1px !important;
    }
    .submission-auth-container-4-bottom-card-form{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    .submission-auth-container-4-bottom-card-bottom-textarea{
        padding: 5px;
        width: 250px;
        height: auto;
    }
    .submission-auth-container-4-bottom-card-form-content{
        padding: 5px;
        text-align: left;
        color: var(--red-color);
    }
    .submission-auth-main-container{
        flex-direction: column;
        padding: 0px;
     }
     .submission-auth-container{
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-direction: column;
        flex-basis: 30%;
        width: 100%;
    }
    .submission-auth-main-inner{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        flex-basis: 20%;
        padding: 1px;
        line-height: 1.3;
    }
    .submission-auth-main-inner li{
    font-weight: bold;
    font-size: 8px;
    color: var(--dark-blue-color);
    font-style: italic;
    padding: 1px;
    }
    .submission-auth-main-inner li a{
        font-weight: bold;
        color: var(--secondary-color);
        
    }
    .auth-title{
    font-size: 10px; 
    background-color: var(--dark-blue-color);
    width: 100%;
    border-radius: 5px;
    padding: 4px 2px;
    text-align: center;
    }
    .submission-auth-container-btn,.submission-auth-container-btn-active{
        align-self: center;
        display: flex;;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        color: white;
        padding: 1px 1px 1px 3px;
        border-radius: 3px;
        border: none;
        font-weight: bold;
        font-size: 10px;
    }
    .submission-auth-uploader-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        border-radius: 5px;
        width: 100%;
        background-color: var(--light-bg);
    }
    .submission-auth-container-form{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 0;
        width: 100%;
        gap: 5px;
        }
        
}
/* End of body section mobile view */
.submission-success{
color: var(--dark-blue-color);
font-size: 12px;
font-weight: 400;
padding: 15px;
}
.submission-falied{
    color: var(--red-color);
    font-size: 12px;
    font-weight: 400;
    padding: 15px;
}
.submision-text-content{
    font-size: 12px;
    font-weight: bold;
    text-align: left;
}
/* Modal Mobile View Start*/
@media only screen and (max-width:500px) {
    .submision-text-content{
        font-weight: bold;
        text-align: left;
    }
    .modal-container{
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 98% !important;
        height: 100%;
        margin-left: 0px !important;
        margin: auto !important;
    }
    .modal-input{
        padding: 4px;
        font-size: 12px;
        font-weight: lighter;
        color: #333;
        width: 90vw !important;
        border: thin solid gray;
    }
    .modal-form-inner-institution,.modal-form-inner-reason{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 3px;
    }
    .modal-input-institution,.modal-textarea{
        padding: 4px;
        font-size: 12px;
        font-weight: lighter;
        color: #333;
        width: 30% !important;
    }
    .modal-textarea{
        padding: 4px;
        font-size: 12px;
        font-weight: lighter;
        color: #333;
        width: 50% !important;
        height: 10vh;
    }
    .modal-form-info-inner{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        align-self: flex-end;
        gap: 5px;
        width: 100%;
    }
    .submission-auth-main-container-5{
        align-items: baseline;
        flex-direction: column;
        padding: 5px;
        width: 100%;
     }
     .submission-auth-main-container-bottom-down{
        margin: auto;
        width: 100% !important;
        overflow-x: auto !important;
     }
     .submission-auth-main-container-bottom-left{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        flex-basis: 100%;
        width: fit-content;    
        flex-wrap: wrap;
        gap: 5px;
    }
    .submission-auth-main-container-bottom-bottom{ 
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 5px;
            align-self: center;
           
    }
    .submission-auth-main-container-bottom-bottom-back-btn{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px;
        gap: 3px;
        font-size: 10px;
    }
    .submission-auth-main-container-bottom-bottom-proceed-btn{
    display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px;
        gap: 3px;
        font-size: 10px;
    }
    .editorial-login-down-menu-select-container{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start !important;
    }
    .editorial-login-down-menu-items{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start !important;
        gap: 10px;
        padding-top: 50px !important;
        align-self: flex-start !important;
    }
    .menu-toggler-container{
        display: block;
        position: absolute;
        top: 50px;
        right: 0px;
        width: 100% !important;
        background: var(--light-bg) !important;
        padding: 5px !important;
    }
    .close-menu,.open-menu{
        display: block;
    }
.editorial-login-down-menu-min{
    position: relative;
    display: none;
    }
    .editorial-login-down-menu{
        display: none;
    }
    .submission-auth-container-3-bottom{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
    /* Modal Section */
    .modal-main{
        width: 95%;
        height: fit-content;
        z-index: 10000;
        top: 20px;
        justify-content: center;
        align-items: flex-start;
    }
/* .modal-container{
    width: 100%;
    height: auto;
    margin: auto;
} */
.modal-top{
    padding: 5px;
    width: 100%;
    margin: auto;
}
.modal-top-left-item{
    font-size: 10px;
}
.modal-top-right-item{
    font-size: 9px;
    font-weight: lighter;
}
.modal-middle{
    padding: 8px;
}
.modal-middle-left{
    gap: 8px;
}
.modal-middle-right{
    gap: 5px;
}
.modal-form{
 gap: 5px;
 width: 100% !important;
}
.modal-form-inner{
    gap: 5px;
    width: 95%;
    margin: auto;
}
.modal-caption{
    font-size: 8px;
    font-weight: bold;
    text-align: left;
}
.modal-form-info-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0px;
    align-self: flex-start;
    gap: 5px;
    width: 98%;
    padding: 0px 8px;
    margin: auto;
}
.modal-info-caption{
    font-size: 10px;
    font-weight: lighter;
    color: var(--secondary-color);
}
.modal-input{
    padding: 4px;
    font-size: 10px;
    font-weight: lighter;
    color: #333;
    border: thin solid gray;
    width: 65% !important;
}
.modal-form-inner-institution{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 0px;
    gap: 10px;
    padding: 0px 8px;
}

.modal-form-inner-reason{
    display: flex;
    justify-content: flex-end;
    padding: 0px 8px;
    align-items: center;
    margin-left: 0px;
    gap: 10px;
}
.modal-input-institution{
    padding: 4px;
    font-size: 10px;
    width: 35%;
}
.modal-textarea{
    padding: 4px;
    font-size: 10px;
    width: 75% !important;
    margin-bottom: 15px;
}
.modal-info-display{
    padding: 10px;
    font-size: 10px;
    font-weight: lighter;
    color: gray;
    position: absolute;
    left: 50px !important;
    top: 0px !important;
    width: 80% !important;
    background-color: white;
    border: 1px solid gray;
}
.pdf-container{
    width: 100% !important;
    height: auto !important;
    height: 90vh !important;
    margin: auto !important;
    display: flex;
    gap: 15px;
    align-self: flex-end;
    flex-direction: column;
}
.pdf-container-inner-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.pdf-iframe{
    width: 100% !important;
    overflow: hidden !important;
    height: 100vh !important;
}
.download-link{
    cursor: pointer;
    width: fit-content !important;
    padding: 10px !important;
    text-decoration: none !important;
    background-color: var(--primary-color) !important;
    color: white !important;
    border-radius: 4px !important;
}
}
/* Modal Mobile View End*/
.editorial-login-down-menu{
    position: relative;
    /* background-color: transparent !important; */
}
.pdf-container{
    width: 80% !important;
    height: auto !important;
    height: 90vh !important;
    margin: auto !important;
    display: flex;
    gap: 20px;
    align-self: flex-end;
    flex-direction: column;
}
.pdf-container-inner-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.pdf-iframe{
    width: 100% !important;
    overflow: hidden !important;
    height: 100vh !important;
}
.download-link{
    cursor: pointer;
    width: fit-content !important;
    padding: 10px !important;
    text-decoration: none !important;
    background-color: var(--primary-color) !important;
    color: white !important;
    border-radius: 4px !important;
}