.dashboard-main{
width: 100%;
display: flex;
justify-content: space-between;
align-items: baseline;
height: 100vh;
position: relative;
overflow: hidden !important;
}
.dashboard-left{
    flex-basis: 10%;
    width: 100%;
    padding: 20px;
    background-color: rgb(21, 56, 84);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    /* overflow-y: hidden; */
}
.menu-wrapper{
    display: none;
}
@media only screen and (max-width:400px) {
    .dashboard-main{
        flex-direction: column;
        position: relative;
    }
    .dashboard-left{
        display: none !important;
    }
    .dashboard-left-mobile{
        display: flex;
        width: 70%;
        padding: 20px 10px;
        background-color: rgb(21, 56, 84);
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        flex-direction: column;
        position: relative;
        gap: 20px;
    }
    .link-wrapper{
        margin: 20px;
    }
    .dashboard-right{
        flex-basis: 100%;
    }
    .menu-wrapper{
        display: block;
        position: absolute;
        background-color: inherit;
        top: 20px !important;
        right: 20px !important;
    }
}
.link-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
    cursor: pointer;
}
.link-icon{
    color: rgb(171, 211, 245);
}
.link-icon:active{
    color: rgb(29, 31, 80);
}
.link-title{
    font-size: 12px;
    color: rgb(171, 211, 245);
    font-weight: lighter;
}
.dashboard-right{
    flex-basis: 90%;
    width: 100%;
    height: 100vh;
    overflow-y: auto !important;
    padding-bottom: 90px !important;
}
.dashboard-top{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.sort{
    color: gray;
    font-size: 12px;
    font-weight: lighter;
}
.dashboard-bottom{
    width: 100%;
}
.dashboard-content{
    width: 100%;
}
.notifications{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}
.notification{
    color: rgb(8, 12, 123);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
}
.radio{
    outline: none;
    border: none;
}
.radio:active{
    outline: none;
    border: none;
}
.add-new-book-container{
    width: 100%;
}
.add-new-book-item-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.admin-manuscript-approval{
    width: 50%;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}
.admin-manuscript-approval-btn-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}
@media only screen and (max-width:400px){
    .admin-manuscript-approval{
        width: 100%;
    }
}