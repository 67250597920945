*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.alt-contact-info-main{
    padding: 50px 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.editorial-main-register-site-body-top{
    width: 100%;
    margin: auto;
}
.editorial-main-register-site-text-title{
    padding: 10px 0px;
    font-size: 14px;
    font-weight: bold;

}
.editorial-main-register-site-text-content{
    background-color: var(--light-bg);
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
}
.editorial-main-register-site-body{
display: flex;
justify-content: space-around;
align-items: center;
width: 100%;
margin: auto;
background-color: #f4f0f0;
flex-wrap: wrap;
gap: 5px;
}
.alt-contact-info-site-body-left{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-basis: 25%;
width: 100%;
border-right: thin solid rgb(224, 214, 214);
padding-right: 30px;
height: 70vh;
position: relative;
}
.editorial-main-register-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;
    width: 100%;
    background-color: rgb(238, 245, 244);
    border: thin solid gray;
    position: relative;
    padding: 15px;
    max-height: fit-content;
    gap: 5px;
}
/* .choose-register-method-top{
    width: fit-content;
    padding: 0 5px 0 5px;
    background-color: transparent;
    position: absolute;
    line-height: 1;
    top: -30px;
    right: 8px;
} */
.main-register-text-method-top{
    font-size: 10px;
    font-weight: lighter;
    color: #0a0dca;
    padding: 0;
}
.main-register-text-method{
    font-size: 9px;
    font-weight: bold;
    color: #000;
    padding: 0;
}
.alt-contact-info-site-body-left{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.main-register-info{
    font-size: 12px;
    font-weight: lighter;
}
.main-register-btn-group{
    display: flex;
    align-items: center;
    width: fit-content; 
    gap: 15px;  
    padding: 10px;
}
.main-register-form-btn{
    padding: 4px;
    border: thin solid gray;
    color: var(--pale-blue-color);
    font-size: 12px;
    font-weight: lighter;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content; 
    border-radius: 5px;
    box-shadow: 1px 1px 0px 0px gray;
}
.main-register-btn{
    padding: 4px;
    border: thin solid gray;
    background-color: #eee;
    color: gray;
    font-size: 12px;
    font-weight: lighter;
    box-shadow: 1px 1px 0px 0px gray;
}
.alt-contact-info-site-body-left-title{
    font-size: 16px;
    font-weight: 400;  
    text-align: left; 
}
.alt-contact-info-site-body-left-content{
    font-size: 12px;
    font-weight: lighter;   
}
.main-register-text{
    color: gray;
    font-size: 12px;
    font-weight: 400;   
}
.main-register-input-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap:5px;
}
.main-register-text-input-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
/* .main-register-required-text{
    color: rgb(171, 19, 19);
    font-size: 12px;
    font-weight: bold;     
    text-align: right;
} */
/* .main-register-inner-form-right{
    font-size: 12px;
    font-weight: lighter;     
    padding-left: 40px;
    color: var(--secondary-color);
    text-align: left;
} */
.main-editorial-login-input{
    flex-basis: 64%;
    width: 100%;
    font-size: 12px;
    font-weight: lighter;
    padding: 5px;
}
.main-editorial-login-input:focus{
    border: none;
}
.main-register-input-inner-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    /* border-bottom: thin solid rgb(154, 10, 10); */
    padding-bottom: 50px;
}
.main-register-input-and-text-wrapper{
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;
}
.main-register-btn-container{
    flex-basis: 15%;
    width: 100%;
    display: flex;
    align-items: center;
}
.main-register-info-wrapper{
    border-bottom: thin solid rgb(154, 10, 10);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.main-register-form-icon{
    border-radius: 50%;
}
.main-register-form-icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lemon-color);
    border-radius: 50%;
    height: 25px;
    width: 25px;
}
.main-register-form-icon-i{
color: white;
font-weight: bold;
font-size: 18px;
}
.main-register-form-icon-d{
    color: white;
    font-weight: bold;
    font-size: 12px;
}
.main-register-text-caption{
    font-size: 12px;   
    padding: 10px;
    text-align: left;
    align-self: flex-start;
}
.main-register-info-inner-wrapper{
    width: 69%;
    margin-right: 0;
    align-self: flex-end;
    line-height: 1.4;
}
/* .main-register-form-info{
    font-size: 11px;
    font-weight: lighter;
    padding: 10px;
    background-color: white;
} */
/* .main-register-form-info-rules{
    font-size: 10px;
    font-weight: lighter;
    color: red;
} */

.main-register-info-rules-container{
    flex-basis: 25%;
    width: 100%;
    display: flex;
    align-items: center;
}
.phone-type-wrapper{
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
    width: 100%;
}
.phone-type-text{
    font-size: 12px;
    font-weight: lighter;
    margin: 2px;
}
.main-register-info-personal{
    font-size: 12px;
    padding: 10px;   
    font-weight: 400;
    text-align: justify;
    background-color: white;
}
.main-editorial-login-input-right{
    font-size: 12px;
    font-weight: lighter;
    padding: 10px;
}


.main-register-inner-form{
width: 100%;
display: flex;
justify-content: space-between;
gap: 12px;
align-items: center;
}
.main-register-inner-form-left{
flex-basis: 18%;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
/* .main-register-required-text{
    
} */
/* .main-register-inner-form-right{
    flex-basis: 32%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
.main-register-inner-form-middle{
    flex-basis:50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: lighter;
    padding: 5px;
}

.main-register-inner-form-radios-container{
    flex-basis: 15%;
    width: 100%;
}
.main-register-inner-form-radios-items-container{
    flex-basis: 85%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .main-register-inner-form-radios-text{
    color: rgb(171, 19, 19);
    font-size: 10px;
    font-weight: lighter;     
    text-align: right;
} */
.what-is-orcid{
    font-weight: lighter;
    font-size: 10px;
    text-decoration: none;
    cursor: pointer;
}
.what-is-orcid:hover{
text-decoration: underline;
}
.ordic-display{
    color: #333;
    font-size: 10px;
    font-weight: lighter;
    padding: 20px;
    border: thin solid gray;
    background-color: white;
}

.institution-display{
display: flex;
/* justify-content: center;
align-items: center; */
padding: 15px;
border: thin solid gray;
flex-direction: column;
background-color: white;
}
.institution-display-title{
font-size: 12px;
font-weight: 400;
}
.institution-display-instruction{
font-size: 10px;
font-weight: lighter;
}
.institution-display-list{
list-style: none;
font-size: 10px;
font-weight: lighter;
}
.classifications{
    color: red;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
.main-register-btn{
    background-color: rgb(230, 220, 220);
    color: var(--secondary-color);
    box-shadow: 1px 2px 1px 1px rgb(230, 220, 220);
    border-radius: 3px;
}
/* .main-register-form-info-box{
    padding: 20px;
    font-size: 10px;
    font-weight: lighter;
    background-color: white;
} */
.alt-contact-info-modal{
    width: 100%;
    height: 100vh;
    background-color: #eee;
    padding: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
}
.classification-select{
    width: 70%;
    padding: 20px;
    align-self: center;
    background-color: white;
    font-size: 12px;
    font-weight: lighter;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50%;
}
.go-back{
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    color: var(--secondary-color);
    border:thin solid gray;
    border-radius: 3px;
    font-size: 12px;
}

.alt-contact-info-modal-hidden{
display: none;
}
.-hidden{
    display: none;
}

/* Mobile View */
@media only screen and (max-width:500px) {
    .editorial-main-register-form{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-basis: 100%;
        width: 100%;
        background-color: rgb(238, 245, 244);
        border: thin solid gray;
        position: relative;
        padding: 10px;
        max-height: fit-content;
        gap: 5px;
    }
    .main-register-info-inner-wrapper{
        width: 100%;
        margin-right: 0;
        align-self: flex-end;
        line-height: 1.4;
    }
    /* .main-register-form-info{
        text-align: justify;
    } */
    .editorial-main-register-site-body{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: auto;
        background-color: #f4f0f0;
        flex-wrap: wrap;
        padding: 10px;
        gap: 3px;
    }
    .alt-contact-info-site-body-left-content{
        font-size: 12px;
        font-weight: lighter;   
        text-align: justify;
    }
    .main-register-input-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        gap:1px;
   }    
   .main-register-text-input-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.main-register-inner-form{
    gap: 1px;
    align-items: center;
    flex-direction: column;
}
.main-register-inner-form-radios-items-container{
    flex-basis: 100%;
    width: 100%;
    gap: 5px;
    align-self: flex-start;
    width: inherit;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.phone-type-wrapper{
    justify-content: flex-start;
    gap: 5px;
}
.alt-contact-info-site-body-right{
    display: none;
}
}

.date-inpute{
    padding: 5px;
}