.mail-sent-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
}
.mail-sent-card{
    background-color: var(--light-bg);
    border-top: thin solid var(--red-color);
    border-bottom: thin solid var(--red-color);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.mail-sent-card-content{
    padding: 20px;
    font-size: 15px;
    font-weight: 400;
}
.link-to-login{
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: var(--secondary-color) !important;
}

.password-prompt-card{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50vw !important;
    position: absolute;
    bottom: 50px;
    border: thin solid var(--light-bg);
    background-color: white;
}
.password-prompt-error-message{
    font-size: 10px;
    font-size: 500;
    text-align: center;
    color: var(--red-color);
}
.password-reset-form-inner{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    /* padding: 20px; */
 }
.password-reset-form-inner-input-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.password-reset-form-inner-btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
}
.password-reset-form-btn{
    padding: 4px;
    border: thin solid gray;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content; 
    border-radius: 5px;
    box-shadow: 1px 1px 0px 0px gray;
}
/* Mobile */
@media only screen and (max-width:500px) {
    .mail-sent-container{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    }
    .editorial-register-site-body-left-title{
        font-size: 12px;
        font-weight: bold;
    }
    /* .editorial-register-site-body-left-content{
        font-size: 10px;
        line-height: 1.2;
        font-weight: 400;
    } */
    .mail-sent-card{
        background-color: var(--light-bg);
        border-top: thin solid red;
        border-bottom: thin solid red;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .mail-sent-card-content{
        padding: 20px;
        font-size: 10px;
        font-weight: lighter;
    }
    .link-to-login{
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
        color: var(--secondary-color) !important;
    }
    .password-reset-form-inner{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;
     }
    .password-reset-form-inner-input-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .password-reset-form-inner-btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
    }
    .password-reset-form-btn{
        padding: 4px;
        border: thin solid gray;
        color: var(--primary-color);
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 5px;
        width: fit-content; 
        border-radius: 5px;
        box-shadow: 1px 1px 0px 0px gray;
    }
}