.password-change-format{
    width: 50%;
    margin: 20px auto;
    display: flex;
    justify-content: baseline;
    align-items: flex-start;
    flex-direction: column;   
}
.password-change-bottom-container{
    position: relative;
    width: 100%;
    background-color: var(--light-gray-bg);
    margin-top: 30px !important;
    padding: 1px;
    align-self: center !important;
}
.password-change-format-info{
    font-size: 11px;
    color: #333;
}
.change-password-form-inner-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}
.password-change-format-info-text{
    font-size: 11px;
    font-weight: bold;
    color: #333;
}
.change-password-form{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
    width: 100%;
    background-color: var(--light-gray-bg);
}
.password-formatting-bottom-card{
    position: absolute;
    top: 30px;
    padding: 20px;
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
}

.password-formatting-title{
    padding: 10px;
    font-size: 12px;
    width: 100%;
    font-weight: 600;
    text-align: center;
}
.password-change-input{
    height: 16px !important;
    width: 50%;
}
.password-rules-title{
    font-size: 12px;
}
.password-formatting-btn-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 10px;
}
.password-formatting-btn{
    font-size: 10px;
    font-weight: 300;
    color: var(--primary-color);
    padding: 4px;
    border-radius: 4px;
    background-color: var(--light-gray-bg);
}
.password-formatting-bottom-content{
    padding: 1px;
    font-size: 10px;
    margin-top: 2px;
}

/* Mobile */
@media only screen and (max-width:500px){
    .password-change-format{
        width: 100%;
    }
    .password-change-bottom-container{
        width: 90%;
        margin: 20px auto;
        display: flex;
        justify-content: baseline;
        align-items: flex-start;
        flex-direction: column;   
    }
    .password-formatting-bottom-container{
        position: relative;
        width: 100%;
        background-color: var(--light-gray-bg);
        margin-top: 30px !important;
        padding: 10px;
    }
    .password-change-format-info{
        font-size: 11px;
        color: #333;
    }
    .change-password-form-inner-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .password-change-format-info-text{
        font-size: 11px;
        font-weight: bold;
        color: #333;
    }
    .change-password-form{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
        background-color: white;
        box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
        width: 100%;
        background-color: var(--light-gray-bg);
    }
    .password-formatting-bottom-card{
        position: absolute;
        top: 30px;
        padding: 20px;
        background-color: white;
        box-shadow: 1px 1px 1px 1px rgb(220, 220, 220);
    }
    
    .password-formatting-title{
        padding: 10px;
        font-size: 12px;
        width: 100%;
        font-weight: 600;
        text-align: center;
    }
    .password-change-input{
        height: 16px !important;
        width: 50%;
    }
    .password-rules-title{
        font-size: 12px;
    }
    .password-formatting-btn-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 10px;
    }
    .password-formatting-btn{
        font-size: 10px;
        font-weight: 300;
        color: var(--primary-color);
        padding: 4px;
        border-radius: 4px;
        background-color: var(--light-gray-bg);
    }
    .password-formatting-bottom-content{
        padding: 1px;
        font-size: 10px;
        margin-top: 2px;
    }
    
}
