*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.register-main{
    display: flex !important;
    align-items: baseline !important;
    justify-content: center !important;
    width: 100%;
    padding-top: 50px !important;
    margin-top: 30px;
    padding: 10px;
}
.register-site-body-left{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
flex-basis: 25% !important;
width: 100% !important;
border-right: thin solid rgb(224, 214, 214);
padding: 10px 30px 10px 20px;
height: 70vh;
position: relative;
}
.main-register-site-body-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 50% !important;
    gap: 30px;
    width: 100%;
}
.editorial-main-register-site-body{
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
margin: auto;
background-color: #f4f0f0;
flex-wrap: wrap;
gap: 25px;
margin: auto;
padding: 0px !important;
}
.editorial-main-register-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100% !important;
    background-color: var(--light-gray-bg) !important;
    border: thin solid gray;
    position: relative;
    flex-basis: 100% !important;
    padding: 15px;
    max-height: fit-content;
    gap: 5px;
}
.main-register-text-method{
    font-size: 9px;
    font-weight: bold;
    color: #000;
    padding: 0;
}
.main-register-info{
    font-size: 12px;
    font-weight: lighter;
}
.main-register-btn-group{
    display: flex;
    align-items: center;
    width: fit-content; 
    gap: 15px;  
    padding: 10px;
}
.main-register-form-btn{
    padding: 4px;
    border: thin solid gray;
    color: var(--pale-blue-color);
    font-size: 12px;
    font-weight: lighter;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content; 
    border-radius: 5px;
    box-shadow: 1px 1px 0px 0px gray;
}
.register-btn{
    padding: 4px;
    border: thin solid gray;
    background-color: #eee;
    color: gray;
    font-size: 12px;
    align-self: center;
    font-weight: lighter;
    margin-bottom: 50px;
    box-shadow: 1px 1px 0px 0px gray;
}
.editorial-register-site-body-left-title{
    font-size: 16px;
    font-weight: 600 !important;  
    text-align: left; 
}
.editorial-register-site-body-left-content{
    font-size: 14px;
    padding-right: 10px;
    padding-top: 10px;
}
.main-register-text{
    color: gray;
    font-size: 12px;
    font-weight: 400;   
}
.main-register-input-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap:5px;
}
.main-register-text-input-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.main-register-required-text{
    color: rgb(171, 19, 19);
    font-size: 11px;
    font-weight: 500; 
    text-align: right;
}
.main-register-not-required-text{
    font-size: 10px;
    font-weight:500; 
    text-align: right;
    color: var(--secondary-color);
    text-align: left !important;
}
.main-register-inner-form-right{
    font-size: 12px;
    font-weight: 400;     
    color: var(--secondary-color);
    text-align: left;
}
.main-editorial-login-input{
    flex-basis: 64%;
    width: 100%;
    font-size: 12px;
    font-weight: lighter;
    padding: 5px;
}
.main-editorial-login-input:focus{
    border: none;
}
.main-register-input-inner-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    /* border-bottom: thin solid rgb(154, 10, 10); */
    padding-bottom: 50px;
}
.main-register-input-and-text-wrapper{
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: column;
}
.main-register-btn-container{
    flex-basis: 15%;
    width: 100%;
    display: flex;
    align-items: center;
}
.main-register-info-wrapper{
    border-bottom: thin solid rgb(154, 10, 10);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.main-register-form-icon{
    border-radius: 50%;
}
.main-register-form-icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lemon-color);
    border-radius: 50%;
    height: 25px;
    width: 25px;
}
.main-register-form-icon-i{
color: white;
font-weight: bold;
font-size: 18px;
}
.main-register-form-icon-d{
    color: white;
    font-weight: bold;
    font-size: 12px;
}
.main-register-text-caption{
    font-size: 12px;   
    padding: 10px;
    text-align: left;
    align-self: flex-start;
}
.main-register-info-inner-wrapper{
    width: 69%;
    margin-right: 0;
    align-self: flex-end;
    line-height: 1.4;
}
.main-register-form-info{
    font-size: 11px;
    padding: 10px;
    background-color: white;
}
.main-register-form-info-rules{
    font-size: 10px;
    color: red;
}

.main-register-info-rules-container{
    flex-basis: 25%;
    width: 100%;
    display: flex;
    align-items: center;
}
.phone-type-wrapper{
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
    width: 100%;
}
.phone-type-text{
    font-size: 12px;
    font-weight: lighter;
    margin: 2px;
}
.main-register-info-personal{
    font-size: 12px;
    font-weight: lighter;
    padding: 10px;   
    background-color: white;
}
.main-editorial-login-input-right{
    font-size: 12px;
    font-weight: lighter;
    padding: 10px;
}


.main-register-inner-form{
width: 100%;
display: flex;
justify-content: space-between;
gap: 12px;
align-items: center;
}
.main-register-inner-form-left{
flex-basis: 18%;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
.main-register-required-text{
    
}
.main-register-inner-form-right{
    flex-basis: 32%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-register-inner-form-middle{
    flex-basis:50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: lighter;
    padding: 5px;
}

.main-register-inner-form-radios-container{
    flex-basis: 15%;
    width: 100%;
}
.main-register-inner-form-radios-items-container{
    flex-basis: 85%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-register-inner-form-radios-text{
    color: rgb(171, 19, 19);
    font-size: 10px;
    font-weight: 500;     
    text-align: right;
}
.what-is-orcid{
    font-weight: lighter;
    font-size: 10px;
    text-decoration: none;
    cursor: pointer;
}
.what-is-orcid:hover{
text-decoration: underline;
}
.ordic-display{
    color: #333;
    font-size: 10px;
    font-weight: lighter;
    padding: 20px;
    border: thin solid gray;
    background-color: white;
}
.position-icon{
    font-size: 40px !important;
}

.institution-display{
display: flex;
/* justify-content: center;
align-items: center; */
padding: 15px;
border: thin solid gray;
flex-direction: column;
background-color: white;
}
.institution-display-title{
font-size: 12px;
font-weight: 400;
}
.institution-display-instruction{
font-size: 10px;
font-weight: lighter;
}
.institution-display-list{
list-style: none;
font-size: 10px;
font-weight: lighter;
}
.classifications{
    color: red;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}
.main-register-btn{
    background-color: rgb(230, 220, 220);
    color: var(--secondary-color);
    box-shadow: 1px 2px 1px 1px rgb(230, 220, 220);
    border-radius: 3px;
}
.main-register-form-info-box{
    padding: 20px;
    font-size: 10px;
    background-color: white;
}
.editorial-register-modal{
    width: 50%;
    height: 80vh;
    background-color: #eee;
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
}
.classification-select{
    width: 50%;
    padding: 20px;
    align-self: center;
    background-color: white;
    font-size: 12px;
    font-weight: lighter;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50%;
}
.go-back{
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    color: var(--secondary-color);
    border:thin solid gray;
    border-radius: 3px;
    font-size: 12px;
}

.editorial-register-modal-hidden{
display: none;
}
.editorial-register-main-hidden{
    display: none;
}

/* Mobile View */
@media only screen and (max-width:500px) {
    .register-main{
        display: flex !important;
        align-items: baseline !important;
        justify-content: center !important;
        width: 100%;
        flex-direction: column;
        padding-top: 0px !important;
    }
    .editorial-main-register-form{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-basis: 100%;
        width: 100%;
        background-color: rgb(238, 245, 244);
        border: thin solid gray;
        position: relative;
        padding: 10px;
        max-height: fit-content;
        gap: 5px;
    }
    .position-icon{
        font-size: 24px !important;
    }
    .main-register-info-inner-wrapper{
        width: 100%;
        margin-right: 0;
        align-self: flex-end;
        line-height: 1.4;
    }
    .main-register-form-info{
        text-align: justify;
    }
    .register-site-body-left{
        flex-basis: 100% !important;
        border-right: none !important;
        height: fit-content !important;
        margin-bottom: 20px !important;
        margin-top: 10px !important;
    }
    .editorial-main-register-site-body{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: auto;
        background-color: #f4f0f0;
        flex-wrap: wrap;
        padding: 10px;
        gap: 3px;
    }
    /* .editorial-register-site-body-left-content{
        font-size: 12px;
        font-weight: lighter;   
        text-align: justify;
    } */
    .main-register-input-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 80%;
        margin: auto;
        gap:1px;
   }    
   .main-register-text-input-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.main-editorial-login-input{
    flex-basis: 64%;
    width: 100%;
    font-size: 12px;
    font-weight: lighter;
    padding: 10px;
    border: 1px solid #333 !important;
}
.main-register-inner-form-middle{
padding: 10px !important;
border: 1px solid #333 !important;
}
.main-register-inner-form{
    gap: 1px;
    align-items: center;
    flex-direction: column;
}
.main-register-inner-form-radios-items-container{
    flex-basis: 100%;
    width: 100%;
    gap: 1px !important;
    align-self: flex-start;
    width: inherit;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: flex-start;
}
.phone-type-wrapper{
    justify-content: flex-start;
    gap: 5px;
}
.editorial-register-site-body-right{
    display: none;
}
}
.reg-success{
    color: var(--secondary-color);
    font-size: 12px;
    font-weight: 500;
}
.reg-success{
    color: var(--red-color);
    font-size: 12px;
    font-weight: 500;
}
.password-formatting-modal{
    width: 50%;
    margin: 20vh auto;
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;   
    padding: 4px;
    box-shadow: -1px -1px 5px 4px rgb(220, 220, 220);
}
.password-formatting-bottom-container{
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
width: 95%;
margin: auto;
}
.password-formatting-bottom-content,.password-formatting-bottom-content-required{
    padding: 5px;
    font-size: 12px;
    width: 100%;
    font-weight: 100;
    border: 0.1px solid gray;
    margin: auto;
}
.password-formatting-btn{
    padding: 2px;
    margin: 10px 0px 10px 0px;
    border-radius: 4px;
    color: var(--secondary-color);
    background-color: rgb(209, 194, 194);
    border: 1px solid gray;
    padding: 4px 10px 4px 10px;
}
.password-formatting-bottom-content-required{
    color: var(--red-color);
}
.main-register-inner-form-middle-country{
    flex-basis:50% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: lighter;
    padding: 5px;
}
.main-register-inner-form-middle option{
    background-color: var(--light-gray-bg);
    padding: 20px !important;
}
.institution-option{
    padding: 10px !important;
    font-size: 10px;
    font-weight: 400;
}
@media only screen and (max-width:500px){
    .password-formatting-modal{
        width: 90%;
        margin: 10vh auto;
        display: flex;
        justify-content: baseline;
        align-items: center;
        flex-direction: column;   
        padding: 4px;
        box-shadow: -1px -1px 5px 4px rgb(220, 220, 220);
    }
    .password-formatting-bottom-container{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 95%;
        margin: auto;
        }
        .password-formatting-bottom-content,.password-formatting-bottom-content-required{
            padding: 5px;
            font-size: 8px !important;
            width: 100%;
            font-weight: 400;
        }
}