.editorial-overview-main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden !important;
}
.editorial-overview-body{
padding: 20px;
width: 100%;
display: flex;
justify-content: space-around;
align-items: baseline;
}
.editorial-overview-body-left{
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
box-shadow: 1px 0px 0px 0px rgb(220, 220, 220);
flex-basis: 25%;
height: 80vh;
}
.editorial-overview-body-right{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
flex-basis: 60%;
}
.editorial-overview-sub{
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
width: 100%;
/* gap: 30px !important; */
}
.editorial-overview-inner{
    display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
width: 100%;
line-height: 1.5 !important;
}
.editorial-overview-title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px !important;
}
.editorial-overview-inner-child,.editorial-overview-inner-link,.editorial-overview-inner-value{
color: gray;
font-size: 14px;
font-weight: 600 !important;
}
.editorial-overview-inner-link{
    color: var(--secondary-color) !important;
    text-decoration: none;
}
.editorial-overview-inner-value{
    color: var(--lemon-color);
    padding: 5px;
}
.editorial-overview-body-left-title{
/* font-size: 16px; */
font-weight: 600;
}
.editorial-overview-body-left-link{
/* font-size: 12px; */
font-weight: 500;
color: var(--secondary-color) !important;
text-decoration: none;
}
.editorial-overview-inner-wrapper{
    display: inline-block;
}
.auth-wrapper{
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    gap: 20px;
}
.user-auth{
    color: var(--primary-color);
    font-size: 24px;
    cursor: pointer;
}
/* Mobile View */
@media only screen and (max-width:500px) {
    .editorial-overview-body-left{
        padding-right: 10px;
        margin-left: -10px;
        flex-direction: column;
            box-shadow: 1px 0px 0px 0px rgb(220, 220, 220);
            flex-basis: 40%;
            gap: 5px;
        }
    /* .editorial-overview-body-left-title{
        font-weight: 600;
        }
        .editorial-overview-body-left-link{
        
        line-height: 1.3;
        color: var(--secondary-color);
        } */
.editorial-overview-inner-child,.editorial-overview-inner-link,.editorial-overview-inner-value{
    color: gray;
    line-height: 1.3;
}
.editorial-overview-title{
    font-size: 12px;
    font-weight: 600;
}
.editorial-overview-inner{
    display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
line-height: 1.5 !important;
width: 100%;
}
}
.anouncement{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.user-auth-wrapper{
    cursor: pointer;
}
.editorial-login-header-bottom{
    width: 100%;
}
.user-auth-wrapper-card{
    padding: 20px;
    color: #333;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
}
.user-auth-wrapper-card-items{
    color: #333;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    width: 80%;
}
.user-auth-wrapper-card-item{
    font-size: 12px;
    font-weight: 500;
    padding: 10px 0px;
    text-transform: capitalize;
}
.user-auth-wrapper-card-item-anchor{
    text-transform: capitalize;
    padding: 10px 0px;
    border-bottom: none !important;
    text-decoration: none;
}
.user-auth-wrapper-card-item-anchor:hover{
    text-decoration: underline;
}
@media only screen and (max-width:500px){
    .user-auth-wrapper-card{
        width: 100% !important;
    }
    .confirmation-body{
        padding: 1px;
        width: 100%;
        margin: auto;
        gap: 20px;
        align-items: center;
        position: relative;
        }
        .confirmation-body-top{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 1.2 !important;
            overflow: hidden !important;
            margin-bottom: 10px !important;
        }
        .confirmation-body-inner-top{
            gap: 6px;
            overflow: hidden !important;
            margin-bottom: 5px;
        }
        .confirmation-body-inner-top-text{
            font-size: 12px;
            font-weight: bold;
            color: var(--primary-color);
        }
        .confirmation-body-inner-bottom{
            background-color: var(--light-bg);
            padding: 5px !important;
            line-height: 1.2 !important;
        }
        .confirmation-body-top-content{
            font-weight: 400;
        }
        .confirmation-body-bottom{
            width: 100% !important;
            margin: auto !important;
            overflow-x: auto !important;
            overflow-y: hidden !important;
            padding: 5px;
            margin-bottom: 20px !important;
        }
        
        .confirmation-body-bottom table{
            width: 100% !important;
            background-color: #FFFF !important;
        }
        .confirmation-body-bottom thead{
            color: white;
            padding: 10px !important;
            background-color: var(--primary-color);
        }
        tbody td{
            padding: 50px 15px !important;
    font-size: 16px !important;
    border: thin solid #eee !important;
    font-weight: 400 !important;
    color: var(--primary-color);
    text-align: justify;
    width: 20px !important;
        }
        .table-head-icons{
            color: white !important;
            display: flex;
            /* gap: 10px;
            float: right !important; */
        }
        .table-card{
            width: fit-content;
            padding: 20px;
            background-color: white;
            box-shadow: 1px 1px 1px 1px #333;
            width: fit-content !important;
        }
        .table-card li,.table-card a{
            cursor: pointer;
            text-decoration: none;
            color: var(--secondary-color) !important;
            font-weight: bold;
        }
}
.confirmation-body{
padding: 5px;
display: flex;
gap: 30px;
flex-direction: column;
}
.confirmation-body-top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.confirmation-body-inner-top{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    color: var(--primary-color);
}
.confirmation-body-inner-top-text{
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-color);
}
.confirmation-body-inner-top-icon{
    font-size: 24px !important;
}
.confirmation-body-inner-bottom{
    background-color: var(--light-bg);
    padding: 10px;
    line-height: 1;
    margin-bottom: 30px !important;
}

.confirmation-body-inner-bottom p{
    margin: 10px !important;
}
.confirmation-body-top-content{
    font-size: 14px !important;
    font-weight: 400 !important;
    overflow: hidden !important;
    text-align: justify;
}

.confirmation-body-bottom{
    width: 80% !important;
    margin-bottom: 30px !important;
    overflow-x: auto !important;
    padding: 10px !important;
    margin: auto !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.confirmation-body-bottom table{
    background-color: #FFFF !important;
    border-collapse: collapse !important;
    width: 100%;
    margin: auto !important;
}
.confirmation-body-bottom thead{
    padding: 20px !important;
    background-color: var(--primary-color);
}
.table-head-icons{
    color: var(--secondary-color) !important;
    display: flex;
    gap: 20px;
    float: right !important;
    margin-left: 20px !important;
 }
 .table-action-head{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
 }
.confirmation-body-bottom tbody{
    background-color: #FFFF !important;
}
.confirmation-body-bottom thead th{
    padding: 20px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: white !important;
}
 tbody td{
    padding: 50px 15px !important;
    font-size: 16px !important;
    border: thin solid #eee !important;
    font-weight: 400 !important;
    color: var(--primary-color);
    text-align: left;
    width: 20px !important;
}
.table-card{
    width: fit-content;
    padding: 20px;
    background-color: white;
    box-shadow: 1px 1px 1px 1px #333;
}
.table-card li,.table-card a{
    cursor: pointer;
    text-decoration: none;
    color: var(--secondary-color) !important;
    font-size: 10px;
    font-weight: bold;
    margin-top: 5px;
}
.success,.error{
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
    padding: 10px;
    text-align: center;
}
.success{
    color: var(--primary-color);
}
.error{
    color: var(--red-color);
}
.page-control{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--secondary-color);
    cursor: pointer;
}