.header-img-src{
    width:58px !important;
    height:80px !important;
}
.header-img{
    width: 38px !important;
}
.header-login-header-left{
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-login-header{
    position: relative !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding: 0px 30px !important;
    background-color: var(--primary-color);
}
.header-logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--em-bg);
}
.header-login-header-left-left-tilte{
    font-size: 16px;
    color: white;
    line-height: 1.4;
    font-weight: bold;
}
.header-login-header-left-right-title{
    color: white;
    font-size: 16px;
    font-weight: bold;
}
.header-login-header-left-left-separator{
    color: rgb(213, 204, 204);
    font-size: 40px;
    font-weight: 100;
}
.header-login-header-right-title,.header-login-header-right-separator{
    color: white !important;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}
.header-login-header-right{
    display: flex;
    gap: 10px;
    align-items: center;
}
.mobile-nav-down-menu{
    position: relative;
}
.mobile-nav-down-menu-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: -500px !important;
    top: 10px !important;
    width: 600px !important;
    align-self: flex-start !important;
}
.nav-down-menu{
background-color: var(--bottom-menu-bg) !important;
width: 100%;
padding-right: 30px !important;
position: relative;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
}
.header-login-header-left-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.nav-down-menu-items{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
}
.nav-down-menu-link{
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
}
.nav-down-menu-link:hover{
    background-color: var(--light-bg);
}
.nav-down-menu-link{
color: var(--primary-color);
font-weight: 500;
}
.nav-down-menu-select-container{
    border: none !important;
}
.nav-down-menu-selection-1{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    top: 42px;
    left: 260px !important;
    z-index: 9000;
    background-color: var(--light-gray-bg);
}
.nav-down-menu-selection-2{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    top: 42px;
    left: 450px !important;
    z-index: 9000;
    background-color: var(--light-gray-bg);
}
.nav-down-menu-link{

}
.nav-down-menu-select-container{

}

.header-menu-wrapper{
    color: #333;
    position: absolute;
    top: 80px !important;
    left: 10px;
    z-index: 10000;
    margin-bottom: 10px !important;
    background-color: transparent !important;
}
/* Mobile */
/* @media only screen and (max-width:450px){
    .header-menu-wrapper{
        color: #333;
        position: absolute;
        top: 0px !important;
        left: 10px;
        z-index: 10000;
        background-color: transparent !important;
    }
} */

@media only screen and (max-width:500px){
    .mobile-nav-down-menu-items{
        display: none;
    }
    .header-menu-wrapper{
        color: #333;
        position: absolute;
        top: 110px !important;
        left: 20px;
        z-index: 10000;
        background-color: transparent !important;
    }
    .header-login-header-left-right-title{
        font-size: 12px;
        margin-top: -20px;
    }
    .header-login-header{
    justify-content: flex-end !important;
    align-items: center !important;
    }
    .header-login-header-left{
        display: flex;
        justify-content: space-evenly !important;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
        align-self: flex-start !important;
    }
    .header-login-header-right-separator{
        margin-left: 20px !important;
        margin-right: 20px !important;
        color: red;
    }
    .header-login-header-right{
        margin-left: 20px;
    }
    .header-login-header-left-wrapper{
        gap: 0px;
    }
    .header-logo-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        padding: 0 4px 0 4px;
        height: 25px;
        border-radius: 50%;
        background-color: var(--em-bg);
    }
    .header-login-header-left-left-separator{
        font-size: 20px;
        font-weight: 100;
    }
    .header-login-header-left-left-tilte{
        font-size: 8px;
        color: white;
        line-height: 1;
        font-weight: bold;
        overflow: hidden !important;
    }
    .header-img-src{
        width:20px !important;
        height:40px !important;
    }
    .header-img{
        width: 20px !important;
    }
    .nav-down-menu{
    width: 100%;
    padding: 2px 4px 2px 4px;
    position: relative;
    }
    .nav-down-menu-items{
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        align-self: flex-start;
    }
    .nav-down-menu-link{
        padding: 10px;
        cursor: pointer;
        text-decoration: none;
    }
    .nav-down-menu-link:hover{
        background-color: var(--light-bg);
    }
    .nav-down-menu-link{
    color: var(--primary-color);
    font-weight: 500;
    }
    .nav-down-menu-select-container{
        border: none !important;
    }
    .nav-down-menu-selection-1{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        top: 170px;
        padding-right: 50px;
        left: 0px !important;
        z-index: 9000;
        background-color: var(--light-gray-bg);
    }
    .nav-down-menu-selection-2{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: absolute;
        top: 170px;
        padding-right: 50px;
        left: 0px !important;
        z-index: 9000;
        background-color: var(--light-gray-bg);
    }
    .nav-down-menu-link{
    
    }
    .nav-down-menu-select-container{
    
    }
}
@media only screen and (max-width:300px){
    .header-menu-wrapper{
        color: #333;
        position: absolute;
        top: 90px !important;
        left: 5px;
        z-index: 10000;
        background-color: transparent !important;
    }
}
.header-login-header-right-wrapper{
    color: white;
    font-size: 12px;
}